import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../../Components/Navbar/Navbar';
import Footer from '../../../Components/Footer';
import { FaCheck } from 'react-icons/fa';

const PasswordChanged = () => {
  return (
    <div className="flex flex-col justify-between">
      <Navbar />
      <div className="flex flex-col justify-center items-center gap-10 min-h-screen">
        <h1 className="text-4xl font-semibold mb-4">
          Votre mot de passe a été modifié avec succès !
        </h1>
        <FaCheck size={100} className="text-blue-500" />
        <p className="block text-gray-700 text-md">
          Vous pouvez désormais vous connecter avec votre nouveau mot de passe.
        </p>
        <Link
          to="/login"
          className="text-blue-500 underline hover:text-blue-400"
        >
          Se reconnecter
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordChanged;
