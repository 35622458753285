import { api } from './api';
import { type NewMessageBody } from '../../globals';

const endpoints = (builder: any) => ({
  handleNewMessage: builder.mutation({
    query: ({ body }: { body: NewMessageBody }) => ({
      url: `/chatbot/`,
      method: 'POST',
      body: body,
    }),
  }),
});

export const chatbotApi = api.injectEndpoints({ endpoints });
export const { useHandleNewMessageMutation } = chatbotApi;
