import { api } from './api';

const endpoints = (builder: any) => ({
  markAsRead: builder.mutation({
    query: ({ body }: { body: { notificationId: number } }) => ({
      url: `/notification/`,
      method: 'POST',
      body: body,
    }),
  }),
  delete: builder.mutation({
    query: ({ body }: { body: { notificationId: number } }) => ({
      url: `/notification/`,
      method: 'DELETE',
      body: body,
    }),
  }),
});

export const notificationApi = api.injectEndpoints({ endpoints });
export const { useMarkAsReadMutation, useDeleteMutation } = notificationApi;
