import React from 'react';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import Spline from '@splinetool/react-spline';

const Safety = () => {
  return (
    <div>
      <section className="relative z-10 h-auto mb-56" id="benefit">
        <InView trackVisibility delay={500}>
          {({ inView, ref, entry }) => (
            <motion.div
              className="grid xs:grid-cols-1 xl:grid-cols-4 gap-48"
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.3, delay: 0.2 }}
              ref={ref}
            >
              <div className="col-span-1 xs:hidden xl:flex"></div>
              <div className="col-span-1 xs:hidden md:grid place-content-center xs:order-3 xl:order-none">
                <motion.div
                  style={{ marginTop: 'auto', marginBottom: 'auto' }}
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={inView ? { opacity: 1, scale: 1 } : {}}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <Spline scene="https://prod.spline.design/iRH9iJGCMFxA9WYX/scene.splinecode" />
                </motion.div>
              </div>
              <div className="col-span-1 flex justify-center items-center xs:text-center xl:text-left xs:order-2 xl:order-none">
                <div className="flex flex-col">
                  <div
                    className={`flex font-Apfel flex-col gap-2 ${
                      inView ? 'opacity-100' : 'opacity-0'
                    }`}
                  >
                    <motion.h1
                      className="text-5xl font-black"
                      initial={{ opacity: 0, y: 20 }}
                      animate={inView ? { opacity: 1, y: 0 } : {}}
                      transition={{ duration: 0.3, delay: 0.2 }}
                      dangerouslySetInnerHTML={{
                        __html:
                          "La liberté freelance. <br/> La sûreté d'employé.",
                      }}
                    ></motion.h1>
                    <motion.p
                      className="text-xl"
                      initial={{ opacity: 0, y: 20 }}
                      animate={inView ? { opacity: 1, y: 0 } : {}}
                      transition={{ duration: 0.3, delay: 0.4 }}
                    >
                      Tout travail mérite salaire. <br /> Fini les retards de
                      paiement et les missions impayées.
                    </motion.p>
                  </div>
                </div>
              </div>
              <div className="col-span-1 xs:hidden xl:flex"></div>
            </motion.div>
          )}
        </InView>
      </section>
    </div>
  );
};

export default Safety;
