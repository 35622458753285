import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

type PDFFile = string | File | null;

interface PDFViewerProps {
  file: PDFFile;
}

const PDFViewer = ({ file }: PDFViewerProps) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
  };

  return (
    <div className="w-full m-4">
      <div className="flex justify-between my-4">
        <button
          className="rounded-md p-2 bg-blue-400 text-white font-bold
                    disabled:opacity-50 disabled:cursor-not-allowed
                  "
          onClick={handlePreviousPage}
          disabled={pageNumber === 1}
        >
          Précédent
        </button>
        <p>
          Page {pageNumber} sur {numPages}
        </p>
        <button
          className="rounded-md p-2 bg-blue-400 text-white font-bold
                    disabled:opacity-50 disabled:cursor-not-allowed
                  "
          onClick={handleNextPage}
          disabled={pageNumber === numPages}
        >
          Suivant
        </button>
      </div>
      <div className="flex justify-center items-center">
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          <Page
            renderTextLayer={false}
            renderAnnotationLayer={false}
            pageNumber={pageNumber}
          />
        </Document>
      </div>
    </div>
  );
};

export default PDFViewer;
