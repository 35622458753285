import React, { createContext, useContext, useState } from 'react';

interface DarkModeContextType {
  darkMode: string;
  toggleDarkMode: () => void;
}

const DarkModeContext = createContext<DarkModeContextType>({
  darkMode: 'light',
  toggleDarkMode: () => {},
});

const DarkModeProvider = (props: DarkModeProviderProps) => {
  const [darkMode, setDarkMode] = useState('light');
  const toggleDarkMode = () => {
    const newDarkMode = darkMode === 'light' ? 'dark' : 'light';
    setDarkMode(newDarkMode);
    document.body.classList.toggle('dark');
  };

  return (
    <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {props.children}
    </DarkModeContext.Provider>
  );
};

export interface DarkModeProviderProps {
  children: React.ReactNode;
}

export { DarkModeContext, DarkModeProvider };
