import React from 'react';
import Logo from '../assets/white_logo.png';
import LogoV2 from '../assets/logo-v2.png';

type Props = {
  title: string;
  subtitle: string;
  text: string;
  buttonText: string;
  buttonLink: string;
};

const BulleForm = ({
  title,
  subtitle,
  text,
  buttonText,
  buttonLink,
}: Props) => {
  return (
    <div className="flex flex-col pl-4 bg-gradient-to-br from-blue-400 bg-blue-600 relative rounded-3xl h-full max-w-md mx-auto overflow-hidden shadow-lg">
      <div className="flex flex-col h-full p-4 justify-between">
        <div className="flex justify-end">
          <div className="p-2 flex flex-col items-center">
            <img src={Logo} alt="logo" style={{ width: '30px' }} />
            <img src={LogoV2} alt="logo" style={{ width: '40px' }} />
          </div>
        </div>
        <div className="flex-grow flex items-center overflow-hidden">
          <div className="text-center w-full">
            <h2 className="text-white font-bold text-2xl md:text-3xl pb-6 md:pb-10">
              {title}
            </h2>
            <div className="flex flex-col md:flex-row md:justify-center">
              <div className="bg-white/20 backdrop-blur-md rounded-lg px-6 py-4 md:pb-8 md:mr-6 md:max-w-md">
                <h3 className="text-white font-bold text-xl text-center pb-4 md:pb-6">
                  {subtitle}
                </h3>
                <p className="text-white text-sm mt-2">{text}</p>
                {/* Utilisation des props pour le texte et le lien du bouton */}
                <a href={buttonLink} className="block mt-4">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {buttonText}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulleForm;
