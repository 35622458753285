import React, { useState } from 'react';
// Components
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer';
import PricingSection from '../../Components/Landing/PricingSection';
import ComparisonTable from '../../Components/Landing/ComparisonTable';

const Tarification: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className="flex flex-col justify-center items-center pt-20">
        <PricingSection />
      </div>
      <div className="mb-16">
        <ComparisonTable />
      </div>
      <Footer />
    </>
  );
};

export default Tarification;
