import clsx from 'clsx';
import React from 'react';

const RadioField = (props: {
  id: string;
  label: string;
  value: any;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  textColor?: string;
}) => {
  return (
    <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
      <div className="flex items-center pl-3">
        <input
          id={props.id}
          type="radio"
          name={props.name}
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
        />
        <label
          htmlFor={props.id}
          className={clsx(
            'w-full py-3 ml-2 text-sm font-medium',
            props.textColor || 'text-gray-900'
          )}
        >
          {props.label}
        </label>
      </div>
    </li>
  );
};

export default RadioField;
