import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import LogoBlack from '../../assets/logo_black_text_black.svg';
import ResponsiveNavbar from './NavbarResponsive';
import DropDownMenu from './NavbarDropDownMenu';

const Navbar = () => {
  const navigate = useNavigate();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [expandedLabel, setExpandedLabel] = useState<string | null>(null);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth < 1024
  );

  const handleMenuClick = (label: string) => {
    setExpandedLabel((prev) => (prev === label ? null : label));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      setVisible(prevScrollPos > currentScrollPos || currentScrollPos === 0);

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      y: visible ? '0%' : '-100%',
      backgroundColor: expandedLabel
        ? 'rgba(255, 255, 255)'
        : 'rgba(255, 255, 255, 0.8)',
      transition: { duration: 0.3, ease: 'easeInOut' },
    });
  }, [visible, expandedLabel, controls]);

  return (
    <>
      {!isSmallScreen && (
        <motion.nav
          className="fixed w-full z-50"
          animate={controls}
          style={{ backdropFilter: visible ? 'blur(8px)' : 'none' }}
        >
          <motion.div className="container w-full mx-auto lg:px-8 xl:px-32 py-4 flex justify-between items-center font-Apfel">
            <a href="/">
              <motion.img
                src={LogoBlack}
                alt="logo"
                className="w-32"
                transition={{ duration: 0.3 }}
              />
            </a>
            <div className="hidden lg:flex items-center space-x-4">
              <DropDownMenu
                label="Formules"
                expandedLabel={expandedLabel}
                handleMenuClick={handleMenuClick}
                visible={visible}
              />
              <DropDownMenu
                label="À propos"
                expandedLabel={expandedLabel}
                handleMenuClick={handleMenuClick}
                visible={visible}
              />
            </div>
            <div className="hidden lg:flex items-center gap-5">
              <button
                className={
                  'text-md hover:opacity-80 font-semibold py-2 px-4 transition-all rounded-lg min-w-fit text-black border-black'
                }
                onClick={() => navigate('/login')}
                data-testid="sign-up-button"
              >
                Se connecter
              </button>
              <button
                className={
                  'text-md border-2 hover:opacity-80 font-semibold py-2 px-4 transition-all rounded-lg min-w-fit text-black border-black'
                }
                onClick={() => navigate('/register')}
                data-testid="sign-up-button"
              >
                S&apos;inscrire
              </button>
            </div>
          </motion.div>
        </motion.nav>
      )}
      {isSmallScreen && <ResponsiveNavbar />}
    </>
  );
};

export default Navbar;
