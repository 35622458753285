import React from 'react';
import { Blocks } from 'react-loader-spinner';

type Props = {
  primary?: boolean;
  size?: 'small' | 'medium' | 'large';
  children: any;
  onClick?: any;
  loading?: boolean;
  disabled?: boolean;
};

const Button = ({
  primary = false,
  size = 'medium',
  children,
  loading = false,
  disabled = false,
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="button"
      className={`
            ${
              primary
                ? 'bg-primary text-white'
                : 'flex w-fit rounded-md p-2 bg-creamWhite text-primary border border-primary'
            }
            py-2 px-4 transition-all rounded-lg min-w-fit font-normal hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed
            ${
              size === 'small'
                ? 'text-sm'
                : size === 'large'
                ? 'text-lg'
                : 'text-base'
            }
        `}
      disabled={disabled}
      {...props}
    >
      {loading ? (
        <Blocks
          visible={true}
          height="30"
          width="30"
          ariaLabel=""
          wrapperStyle={{}}
          wrapperClass=""
          color="#2850E7"
        />
      ) : (
        <div className="flex items-center justify-center">{children}</div>
      )}
    </button>
  );
};

export default Button;
