import React, { useState } from 'react';
import DashboardLayout from '../../Layout/DashboardLayout';
import { BsBriefcaseFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Table from '../../Components/molecules/Table';
import { AiOutlineEye } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useGetAllClientsQuery } from '../../redux/services/clients';
import { AiFillEdit } from 'react-icons/ai';

const ClientList = () => {
  const { data: clients, isLoading } = useGetAllClientsQuery({});
  const navigate = useNavigate();

  const [valueFilter, setValueFilter] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleClientNameFilterChange(event);
    handleEntrepriseFilterChange(event);
  };

  const handleClientNameFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueFilter(e.target.value);
  };

  const handleEntrepriseFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueFilter(e.target.value);
  };

  const handleFilterButtonClick = () => {
    setShowFilters(!showFilters);
  };

  const filteredClients = (clients as any[])?.filter((client: any) => {
    return (
      client.first_name.toLowerCase().includes(valueFilter.toLowerCase()) ||
      client.last_name.toLowerCase().includes(valueFilter.toLowerCase()) ||
      client.company_name.toLowerCase().includes(valueFilter.toLowerCase())
    );
  });

  const tableData = filteredClients?.map((freelance: any) => ({
    row: [
      `${freelance?.first_name} ${freelance?.last_name}`,
      freelance?.phone,
      freelance?.email,
      freelance?.company_name,
    ],
    id: freelance.id,
    actions: [
      {
        label: <AiOutlineEye size={24} />,
        onClick: () => navigate(`/client/${freelance.id}`),
      },
      {
        label: <AiFillEdit size={24} />,
        onClick: () => navigate(`/client/${freelance.id}/update`),
      },
    ],
  }));

  if (isLoading) {
    return (
      <DashboardLayout>
        <div className="flex justify-center items-center min-h-screen flex-col">
          <h1 className="text-xl font-bold" style={{ color: '#2563EB' }}>
            Chargement de vos clients en cours...
          </h1>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
        <div className="text-4xl md:ml-8 text-mainBlack sm:ml-8 max-[640px]:text-center max-[640px]:my-4 max-[640px]:ml-8">
          Mes clients
        </div>
        <div className="flex flex-col sm:flex-row items-center">
          <div className="flex flex-col sm:flex-row items-center">
            <input
              placeholder="Rechercher un client ou une entreprise"
              type="text"
              id="filter"
              value={valueFilter}
              onChange={handleChange}
              className="border border-gray-300 rounded px-2 py-1 w-96"
            />
          </div>
        </div>
        <Link className="max-[640px]:ml-8" to="/client/new">
          <button className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mr-4 rounded ">
            <BsBriefcaseFill size={20} className="mr-2 text-white" />
            Créer un client
          </button>
        </Link>
      </div>
      <button
        onClick={handleFilterButtonClick}
        className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded "
      >
        {showFilters ? 'Cacher les filtres' : 'Afficher les filtres'}
      </button>
      {showFilters && (
        <div>
          <input
            className="border-primary border-2 rounded-md p-2"
            type="text"
            value={valueFilter}
            onChange={handleClientNameFilterChange}
            placeholder="Client"
          />
          <input
            type="text"
            className="ml-2 border-primary border-2 rounded-md p-2"
            value={valueFilter}
            onChange={handleEntrepriseFilterChange}
            placeholder="Entreprise"
          />
        </div>
      )}
      <Table
        columns={['CLIENT', 'TELEPHONE', 'EMAIL', 'ENTREPRISE', 'ACTIONS']}
        data={tableData}
        checkbox={false}
        // type="client"
      />
    </DashboardLayout>
  );
};

export default ClientList;
