import React from 'react';

const ProgressBar = ({ percent }: any) => {
  return (
    <div className="w-full bg-gray-200 rounded-full overflow-hidden h-3">
      <div
        className="bg-blue-600 text-xs font-medium text-blue-100 text-center h-full leading-none rounded-r-xl"
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
