import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate
import Navbar from '../../Components/Navbar/Navbar';
import { AiFillLinkedin } from 'react-icons/ai';
import ButtonModern from '../../Components/ButtonModern';
import emailjs from '@emailjs/browser';

const ContactPage: React.FC = () => {
  const form = useRef<HTMLFormElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const navigate = useNavigate(); // Utilisez useNavigate

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (form.current) {
      emailjs
        .sendForm(
          'service_y3q27sd',
          'template_s5e4mhh',
          form.current,
          'nMZugF52EIaYUcET-'
        )
        .then(
          (result) => {
            console.log('SUCCESS!', result.status, result.text);
            setSuccess('Votre message a été envoyé avec succès !');
            setLoading(false);
            form.current?.reset();
            navigate('/email-sent');
          },
          (error) => {
            console.error('FAILED...', error.text);
            setError('Une erreur est survenue, veuillez réessayer plus tard.');
            setLoading(false);
          }
        );
    }
  };

  return (
    <>
      <Navbar />
      <div className="font-Apfel min-h-screen flex flex-col justify-center items-center px-4 sm:px-8 md:px-12 lg:px-20 xl:px-24">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-4xl w-full mx-auto"
        >
          <h1 className="text-5xl font-bold mb-8 text-center">
            Contactez-nous
          </h1>
          <div className="bg-white shadow-md rounded px-6 py-8 sm:px-8 sm:py-10 md:px-10 md:py-12 lg:px-16 lg:py-14 xl:px-20 xl:py-16">
            <form ref={form} onSubmit={sendEmail}>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Nom
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  name="user_name"
                  placeholder="John Smith"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  name="user_email"
                  placeholder="john@example.com"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="message"
                  name="message"
                  placeholder="Votre message"
                  rows={6}
                  required
                ></textarea>
              </div>
              <div className="flex justify-center gap-4">
                <ButtonModern
                  basicCase
                  variant="primary"
                  className="relative mt-2 z-50 justify-center items-center gap-2 !text-base font-bold"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Envoi...' : 'Envoyer'}
                </ButtonModern>
              </div>
              <a
                href="https://www.linkedin.com/company/88899960/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:scale-125 transition-all duration-200"
              >
                <AiFillLinkedin
                  color="blue"
                  className="w-[42px] h-auto md:w-[32px]"
                />
              </a>
              {error && <p className="text-red-500 mt-4">{error}</p>}
              {success && <p className="text-green-500 mt-4">{success}</p>}
            </form>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default ContactPage;
