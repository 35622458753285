import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';
import { BsBriefcaseFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import Table from '../../Components/molecules/Table';
import { AiOutlineEye } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useGetAllMissionsQuery } from '../../redux/services/missions';
import {
  useGetUserStripeQuery,
  useLazyGetNewSetupStripeQuery,
} from '../../redux/services/auth';
import { toast } from 'react-toastify';

const MissionList = () => {
  const user = useSelector((state: any) => state.user.user);
  const navigate = useNavigate();
  const { data: missions, isLoading } = useGetAllMissionsQuery({});
  const calculateSprintTotal = (project: any) => {
    return project.sprints.reduce(
      (total: number, sprint: any) => total + sprint.price_ttc,
      0
    );
  };
  const [valueFilter, setValueFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const { data: stripeData } = useGetUserStripeQuery(user?.id);
  const [getNewSetupStripe] = useLazyGetNewSetupStripeQuery();
  const [missionNameFilter, setMissionNameFilter] = useState('');
  const [clientNameFilter, setClientNameFilter] = useState('');

  const needToConnectToStripe = !(stripeData as any)?.stripeAccount
    ?.charges_enabled;

  const handleConnectToStripe = () => {
    getNewSetupStripe(undefined).then((res: any) => {
      window.location.href = res.data.stripeSetupUrl;
    });
  };

  const columns = [
    'MISSION',
    'CLIENT',
    'NOMBRE DE SPRINTS',
    'REVENUS',
    'STATUT',
    'MOYEN DE PAIEMENT',
    'DETAIL',
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleClientNameFilterChange(event);
    handleMissionNameFilterChange(event);
  };

  const handleMissionNameFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueFilter(e.target.value);
  };

  const handleClientNameFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueFilter(e.target.value);
  };

  const handleStatusFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setStatusFilter(e.target.value);
  };
  const handleFilterButtonClick = () => {
    setShowFilters(!showFilters);
  };

  const filteredMissions = (missions as any[])?.filter((mission: any) => {
    const clientName = getClientName(mission.projectUsers[0].usersId);
    const statusText = getStatusText(mission.status);
    const filter = valueFilter.toLowerCase();
    console.log('filter', statusText);

    const nameOrClientMatch =
      mission.name.toLowerCase().includes(filter) ||
      clientName.includes(filter);

    const statusMatch = statusText === statusFilter || statusFilter === '';

    return nameOrClientMatch && statusMatch;
  });

  const tableData = filteredMissions?.map((mission: any) => ({
    row: [
      mission.name,
      getClientName(mission.projectUsers[0].usersId),
      mission.sprints?.length,
      calculateSprintTotal(mission) + '€',
      getStatusText(mission.status),
      getPaymentMethod(mission.projectPayments[0]?.name),
    ],
    id: mission.id,
    actions: [
      {
        label: <AiOutlineEye size={24} />,
        onClick: () => navigate(`/mission/${mission.id}`),
      },
    ],
  }));

  function getPaymentMethod(paymentMethod: string) {
    if (paymentMethod === 'row') {
      return 'Comptant';
    } else if (paymentMethod === 'bank_print') {
      return 'Empreinte bancaire';
    } else {
      return 'Progressif';
    }
  }

  function getClientName(clientId: number) {
    const client = user.freelances.find((c: any) => c.clientId === clientId);
    return client
      ? `${client.client.first_name} ${client.client.last_name}`
      : 'N/A';
  }

  function getStatusText(status: string) {
    if (status === 'completed') {
      return 'Terminée';
    } else if (status === 'in_progress') {
      return 'En cours';
    } else {
      return '';
    }
  }

  if (isLoading) {
    return (
      <DashboardLayout>
        <div className="flex justify-center items-center min-h-screen flex-col">
          <h1 className="text-xl font-bold" style={{ color: '#2563EB' }}>
            Chargement de vos missions en cours...
          </h1>
        </div>
      </DashboardLayout>
    );
  }

  const handleClick = () => {
    if (user.siret === '12345678901234' || user.tva === 'FRXX123456789') {
      toast.warning(
        "Veuillez rentrer SIRET et numéro de TVA dans l'onglet facturation des réglages"
      );
    } else {
      navigate('/mission/new');
    }
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
        <div className="text-4xl md:ml-8 text-mainBlack sm:ml-8 max-[640px]:text-center max-[640px]:my-4 max-[640px]:ml-8">
          Mes missions
        </div>
        {showFilters && (
          <div>
            <input
              type="text"
              className="border-primary border-2 rounded-md p-2"
              value={missionNameFilter}
              onChange={handleMissionNameFilterChange}
              placeholder="Mission"
            />
            <input
              className="ml-2 border-primary border-2 rounded-md p-2"
              type="text"
              value={clientNameFilter}
              onChange={handleClientNameFilterChange}
              placeholder="Client"
            />
            <select
              className="ml-2 border-2 border-primary rounded-md p-2.5"
              value={statusFilter}
              onChange={handleStatusFilterChange}
            >
              <option value="">Toutes</option>
              <option value="En cours">En Cours</option>
              <option value="Terminée">Terminée</option>
            </select>
          </div>
        )}
        <button
          onClick={handleFilterButtonClick}
          className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded "
        >
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </button>
        <button
          className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded"
          onClick={handleClick}
        >
          <BsBriefcaseFill size={20} className="mr-2 text-white" />
          Créer une mission
        </button>
      </div>
      {needToConnectToStripe && (
        <div className="bg-creamWhite mt-4 mb-8 p-4 rounded-[10px] border-red-500 border-[3px] shadow-xl flex flex-row items-center justify-between">
          <div className="text-[20px] text-red-500 text-center">
            Vous devez connecter votre compte à Stripe pour créer une mission
          </div>
          {isLoading && (
            <div className="flex items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-red-500"></div>
            </div>
          )}
          {!isLoading && (
            <a href="#" onClick={handleConnectToStripe}>
              <button className="flex bg-red-600 hover:bg-red-200 text-white py-2 px-4 rounded">
                Connecter à Stripe
              </button>
            </a>
          )}
        </div>
      )}
      <Table
        columns={columns}
        data={tableData}
        checkbox={false}
        // type={'mission'}
      />
    </DashboardLayout>
  );
};

export default MissionList;
