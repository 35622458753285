import React, { useMemo, useState, useEffect } from 'react';
import { fabien, camille, matheo, daniel, morgan } from '../../assets/Team';
import { AiFillLinkedin } from 'react-icons/ai';
import clsx from 'clsx';
import Navbar from '../../Components/Navbar/Navbar';
interface ProfileProps {
  name: string;
  image: string;
  description: React.ReactNode;
  linkedin?: string;
  instagram?: string;
  reverted?: boolean;
  role: string;
}
import Space from '../../assets/ai_space.png';

const Profile = ({
  name,
  image,
  description,
  role,
  linkedin,
  instagram,
  reverted,
}: ProfileProps) => {
  const [showDescription, setShowDescription] = useState(false);
  const [isXsSmScreen, setIsXsSmScreen] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsXsSmScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="font-Apfel flex flex-col justify-center items-center gap-4 w-48 md:w-full relative"
      onMouseEnter={() => setShowDescription(true)}
      onMouseLeave={() => setShowDescription(false)}
    >
      {showDescription && (
        <div
          className={clsx(
            'absolute left-0 right-0 bg-white rounded-lg p-4 z-10 xs:hidden md:block'
          )}
        >
          <p className="text-lg font-semibold">{name}</p>
          <p className="text-sm">{role}</p>
          <div className="text-sm font-semibold text-black xs:hidden xl:block">
            {description}
          </div>
        </div>
      )}
      <img
        src={image}
        alt="profile picture"
        width={600}
        className={clsx(
          'relative inset-x-0 bottom-8 scale-[1.4] transition-transform duration-300 overflow-y-hidden',
          showDescription && 'scale-[1.3]'
        )}
      />
      <span
        className={clsx(
          'text-white z-30 absolute -top-20 md:relative md:top-0 flex justify-center items-center',
          reverted && '-right-48 md:right-0',
          !reverted && '-left-48 md:left-0'
        )}
      >
        <div
          id={reverted ? 'profileLeft' : 'profileRight'}
          className="flex flex-col md:flex-row gap-2 justify-center items-center"
        >
          <p className="relative top-32 md:top-2 font-bold text-xl md:text-xl text-center md:text-black text-white">
            {name}
          </p>
          <div className="relative justify-center items-center top-2">
            {linkedin && (
              <a
                href={linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:scale-125 transition-all duration-200"
              >
                <AiFillLinkedin
                  color={isXsSmScreen ? 'white' : 'black'}
                  className="w-[30px] h-auto md:w-[32px]"
                />
              </a>
            )}
          </div>
        </div>
      </span>
    </div>
  );
};

type Props = {};

const About = (props: Props) => {
  const profileList = useMemo(
    () => [
      {
        name: 'Giraudier Fabien',
        image: fabien,
        role: 'Responsable Design Graphique & développeur Front-End',
        description: (
          <>
            En tant que responsable du design graphique et développeur front
            chez Luter, je fusionne créativité et expertise technique pour
            façonner une expérience utilisateur moderne et intuitive. Ma passion
            pour l&apos;innovation visuelle se traduit par des interfaces
            esthétiques et fonctionnelles.
          </>
        ),
        linkedin: 'https://www.linkedin.com/in/fabien-giraudier',
      },
      {
        name: 'Tirrier Daniel',
        image: daniel,
        role: 'CTO & Responsable Back-End',
        description: (
          <>
            Je mets en œuvre mon expertise technique pour garantir la fiabilité
            et la sécurité de notre plateforme. Mon rôle consiste à concevoir et
            à superviser l&apos;architecture technique qui soutient les
            fonctionnalités essentielles de Luter. Ma passion pour
            l&apos;innovation technologique et mon engagement envers
            l&apos;excellence font de moi un atout précieux pour l&apos;équipe.
          </>
        ),
        linkedin: 'https://www.linkedin.com/in/daniel-tirrier/',
      },
      {
        name: 'Robert Mathéo',
        image: matheo,
        role: 'CEO & développeur Front-End',
        description: (
          <>
            Fondateur passionné et CEO expérimenté, je dirige Luter avec
            détermination et vision. Mon expertise en sécurité des transactions
            et mon leadership ont fait de Luter une référence en matière de
            sécurisation des missions professionnelles.
          </>
        ),
        linkedin: 'https://www.linkedin.com/in/mathéo-robert-3490271b8/',
      },
      {
        name: 'Caschili-Lucas Camille',
        image: camille,
        role: 'Développeur Full-Stack',
        description: (
          <>
            Je suis passionné par la création d&apos;applications web complètes
            et performantes. Ma polyvalence me permet de contribuer à tous les
            aspects du développement, que ce soit côté client ou serveur. Mon
            objectif est de fournir à nos utilisateurs une expérience sans
            faille et hautement fonctionnelle sur la plateforme Luter.
          </>
        ),
        linkedin:
          'https://www.linkedin.com/in/camille-caschili-lucas-575702199/',
      },
      {
        name: 'Ehni Morgan',
        image: morgan,
        role: 'Développeur Front-End',
        description: (
          <>
            Je suis passionné par la création d&apos;interfaces utilisateur
            dynamiques et conviviales. Mon rôle consiste à transformer les
            concepts de design en code fonctionnel, en veillant à ce que chaque
            interaction sur la plateforme soit fluide et intuitive. Mon
            engagement envers l&apos;innovation et la qualité visuelle fait de
            moi un élément clé de l&apos;équipe.
          </>
        ),
        linkedin: 'https://www.linkedin.com/in/morgan-ehni-58726a207/',
      },
    ],
    []
  );

  return (
    <>
      <Navbar />
      <div
        id="about"
        className="font-Apfel min-h-screen flex flex-col justify-around items-center relative gap-12 overflow-x-hidden"
      >
        <img
          src={Space}
          alt="space-bg"
          className="absolute inset-0 w-full h-full object-cover -z-10"
        />
        <h1 className="md:mt-48 xs:mt-32 xs:mb-32 text-4xl md:text-7xl xs:text-3xl font-extrabold text-center text-white relative z-20">
          Notre équipe
        </h1>
        <div className="flex flex-col md:flex-row md:w-full relative">
          {profileList.map((profile, index) => (
            <Profile key={index} {...profile} reverted={index % 2 === 0} />
          ))}
        </div>
        <div className="md:block xs:hidden absolute bottom-0 w-full h-20 bg-white"></div>
      </div>
    </>
  );
};

export default About;
