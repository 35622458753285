import React from 'react';
import FormulaCalculator from './FormulaCalculator';
import { FormStateType } from '../../redux/feature/formSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ButtonModern from '../ButtonModern';
import { FaArrowLeft } from 'react-icons/fa';
import { PiHouseBold } from 'react-icons/pi';

function FormResults({ onPrev }: any) {
  const {
    price,
    duration,
    tarificationHonnete,
    delaisCohérents,
    confiance,
    missions,
    techno,
    clientTravail,
    clientParticulier,
    clientLuter,
  } = useSelector((state: any) => state.form) as FormStateType;

  const navigate = useNavigate();

  return (
    <div className="font-Apfel bg-gray-100 min-h-screen p-8">
      <div className="container mx-auto space-y-12 mt-16">
        <div className="flex justify-start"></div>
        <div className="flex items-center justify-between">
          <ButtonModern
            basicCase
            variant="primary"
            className="flex items-center gap-2 !text-base font-bold"
            onClick={() => navigate('/')}
            Icon={PiHouseBold}
          >
            Accueil
          </ButtonModern>
          <div className="flex-1 flex justify-center">
            <h1 className="text-3xl md:text-5xl font-bold text-gray-800 text-center">
              Résultats de l&apos;analyse
            </h1>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="bg-white rounded-lg p-8 shadow-lg">
            <p className="text-xl text-center font-semibold text-gray-700 mb-6">
              Voici les informations que vous avez renseignées :
            </p>
            <ul className="space-y-4 text-lg text-gray-600">
              <li>
                <span className="font-semibold">Prix total :</span> {price} €
              </li>
              <li>
                <span className="font-semibold">Durée estimée :</span>{' '}
                {duration} J/H
              </li>
              <li>
                <span className="font-semibold">Tarification honnête :</span>{' '}
                {tarificationHonnete}
              </li>
              <li>
                <span className="font-semibold">Délais cohérents :</span>{' '}
                {delaisCohérents}
              </li>
              <li>
                <span className="font-semibold">Niveau de confiance :</span>{' '}
                {confiance}
              </li>
              <li>
                <span className="font-semibold">Missions réalisées :</span>{' '}
                {missions}
              </li>
              <li>
                <span className="font-semibold">Technologie utilisée :</span>{' '}
                {techno}
              </li>
              <li>
                <span className="font-semibold">Travail avec ce client :</span>{' '}
                {clientTravail}
              </li>
              <li>
                <span className="font-semibold">
                  Travail pour clients particuliers :
                </span>{' '}
                {clientParticulier}
              </li>
              <li>
                <span className="font-semibold">Travail pour Luter :</span>{' '}
                {clientLuter}
              </li>
            </ul>
            <div className="mt-8 flex justify-start">
              <ButtonModern
                basicCase
                variant="primary"
                className="flex items-center gap-2 !text-base font-bold"
                onClick={onPrev}
                Icon={FaArrowLeft}
              >
                Modifier
              </ButtonModern>
            </div>
          </div>
          <div className="bg-white rounded-lg p-8 shadow-lg">
            <p className="text-xl text-center font-semibold text-gray-700 mb-6">
              Nous vous recommandons la formule...
            </p>
            <div className="flex justify-center">
              <FormulaCalculator
                price={price}
                duration={duration}
                tarificationHonnete={tarificationHonnete}
                delaisCohérents={delaisCohérents}
                confiance={confiance}
                missions={missions}
                techno={techno}
                clientTravail={clientTravail}
                clientParticulier={clientParticulier}
                clientLuter={clientLuter}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormResults;
