import React from 'react';
import '../styles.css';

// Images
import Wave from '../assets/wave.png';

// Components
import Hero from '../Components/Landing/Hero';
import Navbar from '../Components/Navbar/Navbar';
import Footer from '../Components/Footer';
import BenefitSection from '../Components/Landing/Safety';
import DashboardSection from '../Components/Landing/Dashboard';
import PricingSection from '../Components/Landing/PricingSection';
import ThreeGifSection from '../Components/Landing/ThreeGifSection';
import SquiggleSVG from '../Components/Landing/SquiggleSVG';
import LitigeSection from '../Components/Landing/LitigeSection';

function App() {
  return (
    <div>
      <Navbar />
      <div className="relative min-h-[90vh] w-full flex justify-between items-center">
        <div className="flex flex-col gap-6 md:gap-0 md:flex-row justify-between items-center w-full h-full ">
          <Hero />
        </div>
        <img
          src={Wave}
          alt="wave"
          className="absolute bottom-0 xl:-mb-28 lg:-mb-20 right-0 w-full"
        />
      </div>
      <div className="relative z-10">
        <SquiggleSVG />
        <DashboardSection />
        <BenefitSection />
        <ThreeGifSection />
        <LitigeSection />
        <PricingSection />
        <Footer />
      </div>
    </div>
  );
}

export default App;
