import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import CopyButton from '../../Components/CopyButton';
import { useLazyGetClientByIdQuery } from '../../redux/services/clients';

const ClientDetails = () => {
  const [client, setClient] = useState<any>(null);
  const [getClientById] = useLazyGetClientByIdQuery();
  const { id } = useParams();

  useEffect(() => {
    const getClientData = async () => {
      const clientData = await getClientById(id);
      console.log(clientData);
      setClient(clientData.data);
    };
    getClientData();
  }, [id, client, getClientById]);

  if (id === undefined) {
    return (
      <div>
        <div
          className="flex justify-center items-center min-h-screen
			"
        >
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
        </div>
      </div>
    );
  }

  console.log(client);

  if (!client) {
    return (
      <div>
        {/* put loading spinner here */}
        <div
          className="flex justify-center items-center min-h-screen
			"
        >
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
        </div>
      </div>
    );
  }

  const {
    first_name,
    last_name,
    email,
    phone,
    siret,
    tva_number,
    company_name,
    address,
  } = client;

  const handlePhoneCall = () => {
    const phoneNumber = `tel:${phone}`;
    window.location.href = phoneNumber;
  };

  const handleEmail = () => {
    const emailAddress = `mailto:${email}`;
    window.location.href = emailAddress;
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col sm:flex-row justify-between items-center">
        <div className="text-4xl md:ml-8 text-mainBlack sm:ml-8 max-[640px]:text-center max-[640px]:my-4 max-[640px]:ml-8">
          {first_name + ' ' + last_name}
        </div>
        <Link className="max-[640px]:ml-8" to="/client">
          <button className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded ">
            <AiOutlineArrowLeft size={24} className="mr-2 text-white" />
            Retour
          </button>
        </Link>
      </div>
      <div className="h-2/3 bg-fullWhite mt-4 mt-8 p-8 rounded-[30px] border border-whiteGrey shadow-xl overflow-auto md:ml-8 sm:ml-8 max-[640px]:ml-8 text-xl">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="mb-4">
            <p className="text-primary">
              <strong>Nom de l&apos;entreprise:</strong>
            </p>
            <div className="text-mainBlack">
              {company_name}
              <CopyButton text={company_name} />
            </div>
          </div>
          <div className="mb-4">
            <p className="text-primary">
              <strong>Adresse:</strong>
            </p>
            <div className="text-mainBlack">
              {address}
              <CopyButton text={address} />
            </div>
          </div>
          <div className="mb-4">
            <p className="text-primary">
              <strong>Téléphone:</strong>
            </p>
            <div className="text-mainBlack">
              {phone}
              <CopyButton text={phone} />
            </div>
          </div>
          <div className="mb-4">
            <p className="text-primary">
              <strong>Email:</strong>
            </p>
            <div className="text-mainBlack">
              {email}
              <CopyButton text={email} />
            </div>
          </div>
          <div className="mb-4">
            <p className="text-primary">
              <strong>SIRET:</strong>
            </p>
            <div className="text-mainBlack">
              {siret}
              <CopyButton text={siret} />
            </div>
          </div>
          <div>
            <p className="text-primary">
              <strong>Numéro de TVA:</strong>
            </p>
            <div className="text-mainBlack">
              {tva_number}
              <CopyButton text={tva_number} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-around mt-8 ml-8">
        <button
          className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mt-4 mr-4 rounded"
          onClick={handlePhoneCall}
        >
          Appeler
        </button>
        <button
          className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mt-4 mr-4 rounded"
          onClick={handleEmail}
        >
          Envoyer un Email
        </button>
      </div>
    </DashboardLayout>
  );
};

export default ClientDetails;
