import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useChangePasswordMutation } from '../../../redux/services/auth';
import displayWarningToast from '../../../utils/displayWarningToast';
import Navbar from '../../../Components/Navbar/Navbar';
import Footer from '../../../Components/Footer';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { resetToken } = useParams();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const [changePassword] = useChangePasswordMutation();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      displayWarningToast(
        'Les mots de passe ne correspondent pas.',
        'passwordMismatch'
      );
      return;
    }

    const passwordPattern =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!passwordPattern.test(newPassword)) {
      displayWarningToast(
        'Le mot de passe doit contenir au moins 8 caractères, une lettre majuscule, une lettre minuscule et un chiffre.',
        'invalidPassword'
      );
      return;
    }

    changePassword({ body: { password: newPassword, token: resetToken } })
      .unwrap()
      .then((res: any) => {
        navigate('/reset-password/password-changed');
      })
      .catch((err: any) => {
        console.error('Erreur lors du changement de mot de passe :', err);
        if (err.status === 400)
          displayWarningToast(err.data.error, 'changePasswordFailed');
      })
      .finally(() => {
        //setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Navbar />
      <div className="flex flex-col justify-center items-center gap-10">
        <h2 className="text-2xl font-semibold mb-4">
          Réinitialisation de votre mot de passe
        </h2>
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-80"
        >
          {/* Champ Nouveau mot de passe */}
          <div className="mb-4 relative">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="new-password"
            >
              Nouveau mot de passe
            </label>
            <input
              type={showNewPassword ? 'text' : 'password'}
              id="new-password"
              value={newPassword}
              onChange={handlePasswordChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4 pr-10"
            />
            <span
              className="absolute top-2 right-2 cursor-pointer"
              onClick={toggleShowNewPassword}
            >
              {showNewPassword ? (
                <FiEyeOff className="text-blue-500 -my-1" />
              ) : (
                <FiEye className="text-blue-500 -my-1" />
              )}
            </span>
          </div>

          {/* Champ Confirmer le mot de passe */}
          <div className="mb-4 relative">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="confirm-password"
            >
              Confirmer le mot de passe
            </label>
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirm-password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4 pr-10"
            />
            <span
              className="absolute top-2 right-2 cursor-pointer"
              onClick={toggleShowConfirmPassword}
            >
              {showConfirmPassword ? (
                <FiEyeOff className="text-blue-500 -my-1" />
              ) : (
                <FiEye className="text-blue-500 -my-1" />
              )}
            </span>
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Changer
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
