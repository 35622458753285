import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
// Icon
import { MdEdit, MdSave } from 'react-icons/md';

// Components
import SettingsMenu from '../../Components/Dashboard/SettingsMenu';
import Button from '../../Components/Button';
import DashboardLayout from '../../Layout/DashboardLayout';
import SettingsMenuSmall from '../../Components/Dashboard/SettingsMenuSmall';
import { useDeleteUserMutation } from '../../redux/services/auth';
import displayWarningToast from '../../utils/displayWarningToast';
import ToggleSwitcher from '../../Components/molecules/ToggleSwitcher';

const SettingsDelete: React.FC = () => {
  // useState
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [enabled2, setEnabled2] = useState(false);
  const [enabled3, setEnabled3] = useState(false);
  const [enabled4, setEnabled4] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationInput, setConfirmationInput] = useState('');
  const [deleteUser] = useDeleteUserMutation();
  const [confirmPhrase, setConfirmPhrase] = useState('');

  const handleDeleteConfirmation = () => {
    setIsModalOpen(true);
  };

  const handleDeleteUser = () => {
    setIsLoading(true);
    if (confirmPhrase.toLowerCase() === 'Supprimer') {
      deleteUser({})
        .unwrap()
        .then((res: any) => {
          if (res.status === 403) {
            console.error('Accès refusé pour Supprimer.');
            return;
          }
          navigate('/');
        })
        .catch((err: any) => {
          console.error('Erreur lors de la suppression de compte :', err);
        })
        .finally(() => {
          setIsLoading(false);
          setIsModalOpen(false);
          setConfirmationInput('');
          setConfirmPhrase('');
        });
    } else {
      displayWarningToast(
        'La phrase de confirmation est incorrecte',
        'phraseMismatch'
      );
    }
  };

  return (
    <DashboardLayout>
      <div className="flex">
        <div className="w-full p-4">
          <div className="flex flex-row mb-10">
            <h1 className="text-left text-4xl font-bold bold-4 w-1/2 text-left">
              Paramètres du profil
            </h1>
            {/* <div className="w-1/2 text-right">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => navigate('/profile')}
              >
                Voir votre profil publique
              </button>
            </div> */}
          </div>
          <div className="md:hidden xs:block w-1/4 pr-4">
            <SettingsMenuSmall />
          </div>
          <div className="flex overflow-y-hide p-6 xs:-mr-8 md:mr-0">
            <div className="md:block xs:hidden w-1/4 pr-4">
              <SettingsMenu />
            </div>
            <div className="xs:hidden md:block w-px bg-gray-300 mx-4" />

            <div className="w-full">
              {/* Section 1 */}
              <div className="w-full overflow-y-auto border-2 rounded-[5px] p-4 mb-5">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Messagerie</h1>
                </div>
                <h3 className="text-primary ml-5">
                  Restez informé(e) des messages relatifs à vos missions grâce à
                  nos notifications.
                </h3>
                <hr className="my-2" />
                <div className="flex flex-row mt-10 mx-20">
                  <div className="flex flex-col">
                    <div className="items-center cursor-pointer mb-5">
                      <ToggleSwitcher
                        enabled={enabled}
                        setEnabled={setEnabled}
                      />
                      <p className="flex -mt-7 ml-16 text-black font-bold">
                        Je cesse mon activité de freelance
                      </p>
                    </div>

                    <div className="items-center cursor-pointer mb-5">
                      <ToggleSwitcher
                        enabled={enabled2}
                        setEnabled={setEnabled2}
                      />
                      <p className="flex -mt-7 ml-16 text-black font-bold">
                        J&apos;ai rencontré des difficultés lors de
                        l&apos;utilisation de votre service
                      </p>
                    </div>

                    <div className="items-center cursor-pointer mb-5">
                      <ToggleSwitcher
                        enabled={enabled3}
                        setEnabled={setEnabled3}
                      />
                      <p className="flex -mt-7 ml-16 text-black font-bold">
                        J&apos;ai décidé d&apos;accorder ma confiance à une
                        autre plateforme
                      </p>
                    </div>

                    <div className="items-center cursor-pointer mb-5">
                      <ToggleSwitcher
                        enabled={enabled4}
                        setEnabled={setEnabled4}
                      />
                      <p className="flex -mt-7 ml-16 text-black font-bold">
                        Y&apos;a-t-il quelque chose que nous aurions pu faire
                        pour vous retenir en tant qu&apos;utilisateur(trice) ?
                      </p>
                    </div>
                  </div>
                </div>
                {/* Section 2 */}
                <h2 className="text-gray-800 font-bold text-xl mt-5">
                  Faites-nous part de votre ressenti
                </h2>
                <div className="flex flex-col">
                  <textarea
                    value={feedback}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      setFeedback(e.target.value)
                    }
                    className="w-full h-40 border-2 border-gray-300 rounded-lg p-2 text-gray-800"
                    placeholder="Écrivez ici (maximum 500 caractères)"
                    maxLength={500}
                  />
                  <div className="mt-5 bg-blue-100 rounded-2xl">
                    <h2 className="m-5 text-grey-800 font-bold text-xl mt-5">
                      Lorsque vous cliquerez sur Supprimer...
                    </h2>
                    <p className="m-5">
                      ...nous veillerons à effacer toutes les données
                      personnelles que vous avez fournies lors de votre
                      inscription. Cela comprend la suppression de votre compte,
                      de votre profil, de vos données d&apos;identification,
                      ainsi que de tout autre renseignement personnel que vous
                      avez partagé sur notre plateforme. Veuillez noter que
                      cette suppression est irréversible et que vous ne pourrez
                      pas récupérer vos données une fois la procédure terminée.
                      Nous nous engageons à respecter votre vie privée et à
                      protéger vos informations conformément à notre politique
                      de confidentialité.
                    </p>
                  </div>
                </div>
                <div className="flex justify-center mt-5">
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-center"
                    onClick={handleDeleteConfirmation}
                  >
                    Supprimer
                  </button>

                  {isModalOpen && (
                    <div className="fixed z-50 top-0 left-0 w-full h-full bg-red-600 bg-opacity-50 flex items-center justify-center">
                      <div className="bg-white rounded p-6 w-96">
                        <h2 className="text-xl font-bold mb-4 text-center">
                          Êtes-vous sur de vouloir supprimer definitivement
                          votre compte ?
                        </h2>
                        <h3 className="text-md mb-4 text-center">
                          Ecrivez{' '}
                          <span className="font-bold">
                            &quot;Supprimer&quot;
                          </span>{' '}
                          pour confirmer
                        </h3>
                        <input
                          type="text"
                          placeholder="Saisissez la phrase de confirmation"
                          className="border border-gray-300 p-2 mb-4 w-full"
                          value={confirmPhrase}
                          onChange={(e) => {
                            console.log('Valeur saisie : ', e.target.value);
                            setConfirmPhrase(e.target.value);
                          }}
                        />
                        <div className="flex justify-between">
                          <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={handleDeleteUser}
                          >
                            Confirmer
                          </button>
                          <button
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={() => setIsModalOpen(false)}
                          >
                            Annuler
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SettingsDelete;
