import React from 'react';
import DashboardLayoutClient from '../Layout/DashboardLayoutClient';
import { useSelector } from 'react-redux';

type Props = {};

const FreelancerList = (props: Props) => {
  const freelance = useSelector(
    (state: any) => state.user.user.project?.projectUsers[0].user
  );

  return (
    <DashboardLayoutClient>
      <div className="shadow-2xl bg-creamWhite rounded-[30px] min-h-[79vh] p-6 flex flex-col gap-4 border border-whiteGrey">
        <h1 className="text-3xl font-bold text-mainBlack">
          Informations du freelance
        </h1>
        <h2 className="text-2xl font-bold text-mainBlack mt-8">
          {freelance?.first_name} {freelance?.last_name}
        </h2>
        <div className="bg-fullWhite text-mainBlack rounded-lg p-4">
          {freelance?.company_name && (
            <p>
              <strong>Entreprise:</strong> {freelance?.company_name}
            </p>
          )}
          {freelance?.address &&
            freelance?.zipCode &&
            freelance?.city &&
            freelance?.country && (
              <p>
                <strong>Adresse:</strong>{' '}
                {`${freelance?.address}, ${freelance?.zipCode} ${freelance?.city}, ${freelance?.country}`}
              </p>
            )}
          {freelance?.email && (
            <p>
              <strong>Email:</strong> {freelance?.email}
            </p>
          )}
          {freelance?.phone && (
            <p>
              <strong>Numéro de téléphone:</strong> {freelance?.phone}
            </p>
          )}
          {freelance?.siret && (
            <p>
              <strong>Siret:</strong> {freelance?.siret}
            </p>
          )}
          {freelance?.tva_number && (
            <p>
              <strong>Numéro TVA:</strong> {freelance?.tva_number}
            </p>
          )}
          {/* Add other fields as needed */}
        </div>
      </div>
    </DashboardLayoutClient>
  );
};

export default FreelancerList;
