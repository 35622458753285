import React from 'react';
import LoginForm from '../../Components/LoginForm';
import Navbar from '../../Components/Navbar/Navbar';

const Login = () => {
  return (
    <>
      <Navbar />
      <div className="flex gap-4 flex-col items-center justify-center">
        <LoginForm />
      </div>
    </>
  );
};

export default Login;
