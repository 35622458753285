import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';
import { Blocks } from 'react-loader-spinner';
import { useCreateDisputeMutation } from '../../redux/services/missions';

const MissionNewDispute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [createDispute] = useCreateDisputeMutation();
  const [dispute, setDispute] = useState<{
    name: string;
    description: string;
    disputeDate: string;
    priority: string;
  }>({
    name: '',
    description: '',
    disputeDate: '',
    priority: '',
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);
    await createDispute({
      missionId: parseInt(id as string),
      body: { ...dispute, missionId: parseInt(id as string) },
    })
      .unwrap()
      .then((data: any) => {
        navigate(`/mission/${id}/dispute/${data.dispute.id}`);
      });
    setLoading(false);
  };

  return (
    <DashboardLayout>
      <h2 className="text-mainBlack text-xl font-semibold">
        Déclarer un litige
      </h2>
      <p className="text-sm text-gray-500">
        Déclarez un litige pour signaler un problème avec le client ou le
        projet.
      </p>
      <div className="mt-6 flex flex-col gap-4">
        <label className="flex flex-col gap-2 w-full">
          <span className="text-sm font-medium text-gray-900">
            Intitulé du litige
          </span>
          <input
            type="text"
            required
            onChange={(e) => setDispute({ ...dispute, name: e.target.value })}
            className="border shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md p-2"
          />
        </label>
        <div className="flex gap-4">
          <label className="flex flex-col gap-2 w-full">
            <span className="text-sm font-medium text-gray-900">
              Date du litige
            </span>
            <input
              type="date"
              required
              onChange={(e) =>
                setDispute({ ...dispute, disputeDate: e.target.value })
              }
              className="border shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md p-2"
            />
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="text-sm font-medium text-gray-900">Priorité</span>
            <select
              required
              onChange={(e) =>
                setDispute({ ...dispute, priority: e.target.value })
              }
              className="border shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md p-2"
            >
              <option value="low">Basse</option>
              <option value="medium">Moyenne</option>
              <option value="high">Haute</option>
            </select>
          </label>
        </div>
        <label className="flex flex-col gap-2 w-full">
          <span className="text-sm font-medium text-gray-900">
            Description du litige
          </span>
          <textarea
            required
            onChange={(e) =>
              setDispute({ ...dispute, description: e.target.value })
            }
            className="border shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md p-2"
          />
        </label>
      </div>
      <div className="mt-8">
        <button
          onClick={handleSubmit}
          className="flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700
        disabled:opacity-50 disabled:cursor-not-allowed
        "
          disabled={
            loading ||
            dispute?.name === '' ||
            dispute?.description === '' ||
            dispute?.disputeDate === '' ||
            dispute?.priority === ''
          }
        >
          {loading && (
            <Blocks color="#FFF" height={24} width={24} visible={loading} />
          )}
          {!loading && <span>Enregistrer</span>}
        </button>
      </div>
    </DashboardLayout>
  );
};

export default MissionNewDispute;
