import React from 'react';
import DashboardLayout from '../../Layout/DashboardLayout';

type Props = {};

const DeclarationUrssafScreen = (props: Props) => {
  return (
    <DashboardLayout>
      <div className="bg-white rounded-[30px] min-h-[79vh] p-6 flex flex-col gap-4"></div>
    </DashboardLayout>
  );
};

export default DeclarationUrssafScreen;
