import React from 'react';
import { useParams } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';
import { useGetDisputeQuery } from '../../redux/services/missions';
import moment from 'moment';

const MissionDispute = () => {
  const { id, disputeId } = useParams();

  const { data: disputeDetails, refetch } = useGetDisputeQuery({
    missionId: parseInt(id as string),
    disputeId: parseInt(disputeId as string),
  }) as any;

  return (
    <DashboardLayout>
      <h1 className="text-3xl font-bold text-gray-900">Détails du litige</h1>
      <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {disputeDetails?.dispute?.name}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{`Date du litige: ${
            disputeDetails?.dispute?.disputeDate
              ? moment(disputeDetails?.dispute?.disputeDate).format(
                  'DD/MM/YYYY'
                )
              : 'N/A'
          }`}</p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Priorité</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {disputeDetails?.dispute?.priority
                  ? disputeDetails?.dispute?.priority === 'low'
                    ? 'Basse'
                    : disputeDetails?.dispute?.priority === 'medium'
                    ? 'Moyenne'
                    : disputeDetails?.dispute?.priority === 'high'
                    ? 'Haute'
                    : 'N/A'
                  : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {disputeDetails?.dispute?.description
                  ? disputeDetails?.dispute?.description
                  : 'N/A'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MissionDispute;
