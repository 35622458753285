import React, { useState } from 'react';
import { FiCopy } from 'react-icons/fi';

interface CopyButtonProps {
  text: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <button
      className={`ml-2 text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-300 ${
        isCopied ? 'text-green-500' : ''
      }`}
      onClick={copyToClipboard}
    >
      <FiCopy size={16} />
    </button>
  );
};

export default CopyButton;
