import React, { useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { getCroppedImg } from '../../utils/getCroppedImage';

interface ImageCropperProps {
  profileImage: string;
  setCroppedImage: (image: string) => void;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  profileImage,
  setCroppedImage,
}) => {
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSave = async () => {
    if (croppedAreaPixels) {
      const croppedImage = await getCroppedImg(
        profileImage,
        croppedAreaPixels,
        200,
        200
      );
      setCroppedImage(croppedImage);
    }
  };

  return (
    <div className="crop-container">
      <div className="cropper-wrapper">
        <Cropper
          image={profileImage}
          crop={crop}
          zoom={zoom}
          aspect={1}
          cropShape="round"
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </div>
      <button onClick={handleSave} className="save-button">
        Sauvegarder
      </button>
    </div>
  );
};

export default ImageCropper;
