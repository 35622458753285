import React, { useContext } from 'react';
import { DarkModeContext } from '../../context/DarkContext';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

type Props = {};

const DarkModeSwitcher = (props: Props) => {
  const { darkMode, toggleDarkMode } = useContext(DarkModeContext);
  return (
    <div className="rounded-full bg-whiteGrey hover:bg-lightGrey p-2">
      <DarkModeSwitch
        checked={darkMode === 'dark'}
        onChange={toggleDarkMode}
        size={24}
      />
    </div>
  );
};

export default DarkModeSwitcher;
