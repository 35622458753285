import React, { useState, ChangeEvent, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { SprintType } from '../../globals';
import { MissionInfo } from '../../globals';
import type { SprintElement, SprintInfo } from '../../globals';

type Props = {
  data: MissionInfo;
  setData: any;
};

export const TextPad = ({ data, setData }: Props) => {
  const createSprintElement = (targetSprint: SprintInfo) => {
    setData({
      ...data,
      sprints: data.sprints?.map((sprint: SprintInfo) => {
        if (sprint.id === targetSprint.id) {
          return {
            ...sprint,
            sprint_elements: [
              ...sprint.sprint_elements,
              {
                id: sprint.sprint_elements.length,
                description: '',
                price_ht: 0,
                price_ttc: 0,
                quantity: 0,
              },
            ],
          };
        }
        return sprint;
      }),
    });
  };

  const createSprint = () => {
    setData({
      ...data,
      sprints: [
        ...data.sprints,
        {
          id: data.sprints.length,
          name: '',
          price_ttc: 0,
          price_ht: 0,
          client_approval: false,
          freelance_approval: false,
          sprint_elements: [],
        },
      ],
    });
  };

  const handleAddQuantitySprintElement = (
    _sprint: SprintInfo,
    _sprintElement: SprintElement
  ) => {
    setData({
      ...data,
      sprints: data.sprints?.map((sprint: SprintInfo) => {
        if (sprint.id === _sprint.id) {
          return {
            ...sprint,
            sprint_elements: sprint.sprint_elements.map(
              (sprintElement: SprintElement) => {
                if (sprintElement.id === _sprintElement.id) {
                  return {
                    ...sprintElement,
                    quantity: sprintElement.quantity + 1,
                  };
                }
                return sprintElement;
              }
            ),
          };
        }
        return sprint;
      }),
    });
  };

  const handleRemoveQuantitySprintElement = (
    _sprint: SprintInfo,
    _sprintElement: SprintElement
  ) => {
    setData({
      ...data,
      sprints: data.sprints?.map((sprint: SprintInfo) => {
        if (sprint.id === _sprint.id) {
          return {
            ...sprint,
            sprint_elements: sprint.sprint_elements.map(
              (sprintElement: SprintElement) => {
                if (sprintElement.id === _sprintElement.id) {
                  return {
                    ...sprintElement,
                    quantity:
                      sprintElement?.quantity === 0
                        ? 0
                        : sprintElement?.quantity - 1,
                  };
                }
                return sprintElement;
              }
            ),
          };
        }
        return sprint;
      }),
    });
  };

  const handleOnChangePrice = (
    e: ChangeEvent<HTMLInputElement>,
    _sprint: SprintInfo,
    _sprintElement: SprintElement
  ) => {
    setData({
      ...data,
      sprints: data.sprints?.map((sprint: SprintInfo) => {
        if (sprint.id === _sprint.id) {
          return {
            ...sprint,
            sprint_elements: sprint.sprint_elements.map(
              (sprintElement: SprintElement) => {
                if (sprintElement.id === _sprintElement.id) {
                  return {
                    ...sprintElement,
                    price_ht: parseInt(e.target.value),
                    price_ttc: Math.round(parseInt(e.target.value) * 1.2),
                  };
                }
                return sprintElement;
              }
            ),
          };
        }
        return sprint;
      }),
    });
  };

  const handleOnChangeDescription = (
    e: ChangeEvent<HTMLInputElement>,
    _sprint: SprintInfo,
    _sprintElement: SprintElement
  ) => {
    setData({
      ...data,
      sprints: data.sprints?.map((sprint: SprintInfo) => {
        if (sprint.id === _sprint.id) {
          return {
            ...sprint,
            sprint_elements: sprint.sprint_elements.map(
              (sprintElement: SprintElement) => {
                if (sprintElement.id === _sprintElement.id) {
                  return {
                    ...sprintElement,
                    description: e.target.value,
                  };
                }
                return sprintElement;
              }
            ),
          };
        }
        return sprint;
      }),
    });
  };

  const handleOnChangeName = (
    e: ChangeEvent<HTMLInputElement>,
    _sprint: SprintInfo
  ) => {
    setData({
      ...data,
      sprints: data.sprints?.map((sprint: SprintInfo) => {
        if (sprint.id === _sprint.id) {
          return {
            ...sprint,
            name: e.target.value,
          };
        }
        return sprint;
      }),
    });
  };

  const handleDeleteSprint = (_sprint: SprintInfo) => {
    setData({
      ...data,
      sprints: data.sprints?.filter((sprint: SprintInfo) => {
        return sprint.id !== _sprint.id;
      }),
    });
  };

  const handleDeleteSprintElement = (
    _sprint: SprintInfo,
    _sprintElement: SprintElement
  ) => {
    setData({
      ...data,
      sprints: data.sprints?.map((sprint: SprintInfo) => {
        if (sprint.id === _sprint.id) {
          return {
            ...sprint,
            sprint_elements: sprint.sprint_elements.filter(
              (sprintElement: SprintElement) => {
                return sprintElement.id !== _sprintElement.id;
              }
            ),
          };
        }
        return sprint;
      }),
    });
  };

  useEffect(() => {
    const updatedData = {
      ...data,
      sprints: data.sprints?.map((sprint: SprintInfo) => {
        const totalPriceHT = sprint.sprint_elements?.reduce(
          (acc: number, sprintElement: SprintElement) => {
            return acc + sprintElement?.price_ht * sprintElement?.quantity;
          },
          0
        );

        return {
          ...sprint,
          price_ht: totalPriceHT,
          price_ttc: Math.round(totalPriceHT * 1.2),
        };
      }),
    };

    if (JSON.stringify(updatedData.sprints) !== JSON.stringify(data.sprints)) {
      setData(updatedData);
    }
  }, [data, setData]);

  return (
    <div className="overflow-scroll">
      {data.sprints?.length ? (
        data.sprints?.map((sprint: SprintInfo, index: number) => {
          return (
            <div key={sprint.id} className="flex flex-col gap-4">
              <div
                className="flex flex-row justify-between items-center w-full"
                key={sprint.id}
              >
                <h1 className="my-8 font-bold text-xl">{`Détail du sprint #${
                  index + 1
                }`}</h1>
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold p-2 rounded-md text-sm"
                  onClick={() => handleDeleteSprint(sprint)}
                >
                  Supprimer
                </button>
              </div>
              <input
                type="text"
                className="border border-lightGrey rounded-md w-full p-2"
                placeholder="Ex : Mise en place de l'architecture"
                onChange={(e: any) => handleOnChangeName(e, sprint)}
                value={sprint.name}
              />
              <div className="flex flex-row justify-center items-center">
                <h1>Prix unit. (HT): </h1>
                <p>{sprint.price_ht} €</p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className="flex flex-col justify-center items-center border border-dashed border-lightGrey rounded-md w-full p-2 gap-2">
                  {sprint.sprint_elements?.length ? (
                    sprint.sprint_elements?.map(
                      (sprintElement: SprintElement, index: number) => {
                        return (
                          <div
                            className="flex flex-col justify-between items-center border border-lightGrey rounded-md w-full p-2"
                            key={sprintElement.id}
                          >
                            <div
                              className="flex flex-row justify-between items-center w-full"
                              key={sprintElement.id}
                            >
                              <h1 className="my-8 font-bold text-sm">{`Détail du sous-élément #${
                                index + 1
                              }`}</h1>
                              <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold p-2 rounded-md text-sm"
                                onClick={() =>
                                  handleDeleteSprintElement(
                                    sprint,
                                    sprintElement
                                  )
                                }
                              >
                                Supprimer
                              </button>
                            </div>
                            <label className="mb-4">Prix unit. (HT)</label>
                            <input
                              type="number"
                              className="mb-8 border border-lightGrey rounded-md w-full p-2"
                              onWheel={(e) => e.preventDefault()}
                              onChange={(e: any) =>
                                handleOnChangePrice(e, sprint, sprintElement)
                              }
                              value={sprintElement.price_ht}
                            />
                            <h1 className="mb-4">Description</h1>
                            <input
                              type="text"
                              className="mb-8 border border-lightGrey rounded-md w-full p-2"
                              placeholder="Ex : Mise en place de l'architecture"
                              value={sprintElement.description}
                              onChange={(e) =>
                                handleOnChangeDescription(
                                  e,
                                  sprint,
                                  sprintElement
                                )
                              }
                            />
                            <h1 className="mb-4">Quantité</h1>
                            <div className="flex items-center">
                              <button
                                type="button"
                                className="bg-lightGrey rounded-full p-2"
                                onClick={() =>
                                  handleRemoveQuantitySprintElement(
                                    sprint,
                                    sprintElement
                                  )
                                }
                              >
                                -
                              </button>
                              <span className="mx-2">
                                {sprintElement.quantity}
                              </span>
                              <button
                                type="button"
                                className="bg-lightGrey rounded-full p-2"
                                onClick={() =>
                                  handleAddQuantitySprintElement(
                                    sprint,
                                    sprintElement
                                  )
                                }
                              >
                                +
                              </button>
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="italic">
                      Aucun sous-élément pour ce sprint
                    </div>
                  )}
                  <button
                    onClick={() => createSprintElement(sprint)}
                    className="mt-8 border border-dashed border-lightGrey rounded-md w-full pb-2"
                  >
                    + Ajouter un sous-élément au sprint
                  </button>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="italic text-center">
          Aucun sprint pour cette mission
        </div>
      )}
      <button
        onClick={createSprint}
        className="mt-8 border border-dashed border-lightGrey rounded-md w-full p-2"
      >
        + Ajouter un élément
      </button>
    </div>
  );
};
