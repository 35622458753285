import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

// Icons
import { FaCheck } from 'react-icons/fa';
import { TbTestPipe } from 'react-icons/tb';
import { HiSparkles } from 'react-icons/hi2';
import { IoIosSettings } from 'react-icons/io';
import { FaBoltLightning } from 'react-icons/fa6';
import { FaArrowTrendUp } from 'react-icons/fa6';
import { GiBrickWall } from 'react-icons/gi';
import { MdRocketLaunch } from 'react-icons/md';
import { FaTrophy } from 'react-icons/fa';

import Navbar from '../../Components/Navbar/Navbar';

interface TimelineEvent {
  id: number;
  date: string;
  title: string;
  description: string;
  icon: React.ReactNode;
}

const Timeline: React.FC = () => {
  const events: TimelineEvent[] = [
    {
      id: 1,
      date: '11/2022',
      title: 'Piscine Moonshot',
      description:
        "Au cours de la Piscine Moonshot, une initiative intensive de deux semaines dédiée à l'idéation, nous avons abordé la première phase de notre projet. Quatre grandes thématiques ont été proposées durant la première semaine : climat, santé, économie et société. Après avoir choisi notre domaine d'intervention, nous avons élaboré un Proof of Concept (POC) pour évaluer la faisabilité de notre idée.",
      icon: <HiSparkles />,
    },
    {
      id: 2,
      date: '01/2023',
      title: 'Validation du projet et MVP',
      description:
        "Nous avons entrepris la validation rigoureuse de notre idée en démontrant sa faisabilité et en vérifiant qu'elle répondait à un besoin concret du marché. Cette phase a culminé avec la création d'un MVP, une version initiale de notre application, dotée uniquement des fonctionnalités essentielles. Ce produit nous a permis de recueillir des retours précieux de la part des utilisateurs potentiels. Nous avons ensuite présenté notre MVP aux autres étudiants du campus pour un retour d'expérience plus large.",
      icon: <FaCheck />,
    },
    {
      id: 3,
      date: '04/2023 - 06/2023',
      title: 'Sprint Test & Learn',
      description:
        "Durant ce sprint, notre objectif principal était de valider notre concept sur le terrain. Nous avons testé notre projet en conditions réelles et recueilli des retours détaillés de nos utilisateurs cibles. Cette phase nous a également permis de tirer des leçons de nos erreurs et de nos succès, afin d'affiner et d'améliorer continuellement notre projet.",
      icon: <TbTestPipe />,
    },
    {
      id: 4,
      date: '07/2023 - 10/2023',
      title: 'Sprint Management & Processes',
      description:
        'Ce sprint a été axé sur la structuration et la gestion efficace de notre projet. Nous avons mis en place des processus organisationnels clairs pour optimiser notre travail et celui de notre équipe. De plus, nous avons développé des compétences en gestion du temps et des ressources, essentielles pour mener notre projet à bien.',
      icon: <IoIosSettings />,
    },
    {
      id: 5,
      date: '09/2023 - 01/2024',
      title: 'Sprint Fast Forward',
      description:
        "L'objectif de ce sprint était de propulser notre projet vers une nouvelle phase de développement accéléré. Nous avons élaboré et mis en œuvre des stratégies visant à booster la progression de notre projet et à atteindre nos objectifs de manière plus rapide et efficace.",
      icon: <FaBoltLightning />,
    },
    {
      id: 6,
      date: '02/2024 - 05/2024',
      title: 'Sprint Beta Growth Hacking',
      description:
        "Ce sprint était consacré à la croissance et à l'expansion de notre projet. Nous avons déployé des stratégies de growth hacking pour attirer de nouveaux utilisateurs et accroître la notoriété de notre produit. Nous avons également appris à mesurer, analyser et interpréter les indicateurs de croissance afin de prendre des décisions éclairées pour l'avenir de notre projet.",
      icon: <FaArrowTrendUp />,
    },
    {
      id: 7,
      date: '06/2024 - 09/2024',
      title: 'Sprint Consolidation',
      description:
        'Durant ce sprint, nous nous sommes concentrés sur la consolidation des acquis et la préparation du lancement officiel de notre projet. Nous avons mis en place des stratégies robustes pour assurer la pérennité de notre produit sur le marché, tout en finalisant les derniers détails avant le lancement.',
      icon: <GiBrickWall />,
    },
    {
      id: 8,
      date: '10/2024 - 12/2024',
      title: 'Sprint Launch & Metrics',
      description:
        "Ce sprint marquera le lancement officiel de notre projet. Nous déploierons notre produit sur le marché et commencerons immédiatement à surveiller ses performances à travers divers indicateurs clés. En analysant les résultats obtenus, nous ajusterons notre stratégie pour maximiser l'impact et répondre aux attentes des utilisateurs.",
      icon: <MdRocketLaunch />,
    },
    {
      id: 9,
      date: '01/2025',
      title: 'Soutenance finale & Epitech Experience',
      description:
        "La soutenance finale sera l'occasion de présenter notre projet devant un jury d'experts. Nous exposerons en détail notre démarche, notre stratégie de développement, ainsi que les résultats obtenus tout au long du projet. Nous défendrons également notre projet en répondant aux questions du jury, démontrant ainsi la solidité de notre travail et notre capacité à mener à bien une initiative complexe. Cette présentation marquera l'aboutissement de notre parcours et notre participation à l'Epitech Experience.",
      icon: <FaTrophy />,
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="container mx-auto px-4 font-Apfel">
        <div className="relative wrap overflow-hidden p-10 h-full flex flex-col pt-20">
          <div
            className="border border-primary border-4 absolute h-full border rounded-full"
            style={{ left: '50%' }}
          ></div>
          {events.map((event, index) => (
            <TimelineItem key={event.id} event={event} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

interface TimelineItemProps {
  event: TimelineEvent;
  index: number;
}

const TimelineItem: React.FC<TimelineItemProps> = ({ event, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
      className={`my-8 flex justify-between items-center ${
        index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'
      }`}
    >
      <div
        className="z-20 absolute bg-primary shadow-xl w-14 h-14 rounded-full"
        style={{ left: '48.3%' }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '30px',
            color: '#FFFFFF',
          }}
        >
          {event.icon}
        </div>
      </div>
      <div className="w-5/12 px-10 py-6 rounded-lg shadow-xl bg-gray-100">
        <h4 className="mb-3 text-gray-500 text-md">{event.date}</h4>
        <h3 className="mb-2 font-black text-primary text-2xl">{event.title}</h3>
        <p className="text-lg leading-snug tracking-wide text-gray-900 text-opacity-100">
          {event.description}
        </p>
      </div>
    </motion.div>
  );
};

export default Timeline;
