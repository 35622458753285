import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Spline from '@splinetool/react-spline';
import Space from '../../assets/ai_space.png';
import ButtonModern from '../ButtonModern';
import { FaArrowRight } from 'react-icons/fa';

const Hero = () => {
  const navigate = useNavigate();
  const aboutRef = useRef<HTMLDivElement>(null);

  const handleCTAClick = () => {
    navigate('/tarification');
  };

  const scrollToAbout = () => {
    const aboutElement = document.querySelector('#dashboard');
    if (aboutElement) {
      aboutElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  return (
    <section className="relative w-full min-h-screen mx-auto flex flex-wrap items-center overflow-hidden">
      <img
        src={Space}
        alt="space-bg"
        className="absolute inset-0 w-full h-full object-cover -z-10"
      />
      <div className="w-full lg:w-1/2 xl:w-3/5 px-8 lg:px-16 -mt-20 font-Apfel">
        <h1 className="text-white text-6xl lg:text-6xl xl:text-7xl md:text-6xl md:my-20 sm:text-5xl sm:my-20 xs:text-5xl xs:mt-32 font-extrabold">
          Votre activité freelance.
        </h1>
        <h1 className="text-white text-6xl lg:text-6xl xl:text-7xl md:text-6xl md:my-20 md:-mt-20 xs:text-5xl xs:text-5xl font-extrabold">
          Dans votre bulle.
        </h1>
        <h2 className="text-white text-base lg:text-lg xl:text-xl mb-6">
          Sécurisez vos prestations de services et échanges de fonds avec Luter.
        </h2>
        <ul className="text-white list-disc list-inside mb-6">
          <li>Aucun impayé ni retard de paiement</li>
          <li>Accompagnement personnalisé</li>
          <li>Résolution de litige</li>
        </ul>
        <p className="text-white text-sm italic">
          Première mission offerte sous condition*
        </p>
        <ButtonModern
          basicCase
          variant="secondary"
          className="relative mt-2 z-50 justify-center items-center gap-2 !text-base font-bold"
          onClick={handleCTAClick}
        >
          Commencer une mission
        </ButtonModern>
      </div>
      <div
        className="absolute w-full lg:w-2/2 xl:w-5/5 lg:visible xs:invisible h-full"
        ref={aboutRef}
      >
        <Spline
          scene="https://prod.spline.design/3hcNuS3TzWwPjWAp/scene.splinecode"
          className="lg:scale-100 md:m-0 md:scale-50 sm:-mx-10 sm:scale-25 w-full h-full object-cover"
        />
      </div>
      <div className="absolute xs:bottom-20 md:bottom-40 w-full flex justify-center items-center">
        <div
          onClick={scrollToAbout}
          className="w-[35px] h-[64px] rounded-3xl border-4 border-white border-secondary flex justify-center items-center cursor-pointer xs:hidden md:flex"
        >
          <motion.div
            animate={{
              y: [-15, 20, -15],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              repeatType: 'loop',
            }}
            className="w-3 h-3 rounded-full bg-white mb-1"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
