import React from 'react';
import { IoIosHelpBuoy } from 'react-icons/io';
import { AiOutlineUser } from 'react-icons/ai';
import { type chatHistory } from '../../globals';

export const ChatbotFeedMessage = ({
  reverse,
  message,
}: ChatbotFeedMessageProps) => {
  return (
    <div
      className={`flex justify-center items-center gap-2 ${
        reverse ? 'flex-row-reverse' : 'flex-row'
      }`}
    >
      <div className="p-2 bg-creamWhite rounded-full bg-gradient flex justify-center items-center">
        {reverse ? (
          <AiOutlineUser size={24} color="white" />
        ) : (
          <IoIosHelpBuoy size={24} color="white" />
        )}
      </div>
      <div
        className={`w-full ${
          reverse ? 'flex justify-end' : 'flex justify-start'
        }`}
      >
        <div className="w-fit max-w-[90%] rounded-md shadow-md p-2">
          <p
            className={`text-sm ${reverse ? 'text-right' : 'text-left'}`}
            style={{ wordBreak: 'break-word' }}
          >
            {message.content}
          </p>
        </div>
      </div>
    </div>
  );
};

export interface ChatbotFeedMessageProps {
  reverse?: boolean;
  message: chatHistory;
}

export default ChatbotFeedMessage;
