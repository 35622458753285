import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BiLinkExternal } from 'react-icons/bi';

export const ChatbotHeader = ({ onClickClose }: ChatbotHeaderProps) => {
  return (
    <div className="flex justify-between px-4 py-2 w-full bg-gradient rounded-tr-md rounded-tl-xl">
      <div className="flex gap-2 justify-center items-center">
        <div className="text-white font-bold text-lg">Need help ?</div>
        <a className="flex gap-2 text-white justify-center items-center hover:cursor-pointer">
          FAQ <BiLinkExternal size={18} />
        </a>
      </div>
      <button onClick={onClickClose} className="text-white">
        <AiOutlineClose size={24} />
      </button>
    </div>
  );
};

export interface ChatbotHeaderProps {
  onClickClose: any;
}

export default ChatbotHeader;
