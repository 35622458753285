import { Area } from 'react-easy-crop';

export const getCroppedImg = (
  imageSrc: string,
  pixelCrop: Area,
  outputWidth: number = 200, // Largeur souhaitée de l'image finale
  outputHeight: number = 200 // Hauteur souhaitée de l'image finale
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        return reject(new Error('Could not get canvas context'));
      }

      // Définir les dimensions du canevas selon la taille souhaitée
      canvas.width = outputWidth;
      canvas.height = outputHeight;

      // Dessiner l'image recadrée et redimensionnée sur le canevas
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        outputWidth,
        outputHeight
      );

      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], 'croppedImage.jpg', {
            type: 'image/jpeg',
          });
          resolve(URL.createObjectURL(file));
        } else {
          reject(new Error('Canvas is empty'));
        }
      }, 'image/jpeg');
    };
    image.onerror = reject;
  });
};
