import React, { useState } from 'react';
import { RiContactsBookFill } from 'react-icons/ri';
import { AiFillBank } from 'react-icons/ai';
import { BsFillGrid1X2Fill, BsBriefcaseFill } from 'react-icons/bs';
import Logo from '../assets/logo_luter_final.svg';
import { Link, redirect } from 'react-router-dom';
import { logout } from '../redux/feature/userSlice';
import { useDispatch } from 'react-redux';

type Props = {
  username: string;
};

const Sidebar: React.FC<Props> = ({ username }) => {
  const dispatch = useDispatch();

  // État pour stocker l'élément sélectionné
  const [selected, setSelected] = useState<string>(() => {
    // Initialiser à partir de localStorage, ou défaut 'dashboard'
    return localStorage.getItem('selectedItem') || 'dashboard';
  });

  // Sauvegarder l'élément sélectionné dans localStorage
  const handleSelection = (name: string) => {
    setSelected(name);
    localStorage.setItem('selectedItem', name);
  };

  return (
    <div className="inset-0 mx-8 mt-8 h-[90vh] z-50 w-fit hidden xl:flex">
      {/* Sidebar */}
      <div className="flex flex-col justify-between items-center w-64 h-full overflow-y-auto bg-creamWhite shadow-2xl rounded-[30px] p-6 border border-whiteGrey">
        <div className="flex flex-col justify-center items-center gap-8">
          <div className="flex items-center justify-center gap-2">
            <img src={Logo} alt="Logo" className="w-10 h-auto" />
            <p className="text-primary text-4xl">luter</p>
          </div>
          <div className="text-center">
            <p className="mt-8 mb-2 text-mainBlack">Bonjour,</p>
            <p className="text-primary text-xl mb-4">{username}</p>
          </div>
          <div className="flex flex-col">
            <ul className="flex flex-col justify-center">
              <li className="mb-8" onClick={() => handleSelection('dashboard')}>
                <Link to="/dashboard">
                  <button
                    className={`flex items-center ${
                      selected === 'dashboard'
                        ? 'text-primary'
                        : 'text-mainBlack hover:text-primary'
                    }`}
                  >
                    <BsFillGrid1X2Fill
                      size={18}
                      className={`mr-2 ${
                        selected === 'dashboard'
                          ? 'text-primary'
                          : 'text-primary hover:text-primary'
                      }`}
                    />
                    <span className="text-lg">Dashboard</span>
                  </button>
                </Link>
              </li>
              <li className="mb-8" onClick={() => handleSelection('mission')}>
                <Link to="/mission">
                  <button
                    className={`flex items-center ${
                      selected === 'mission'
                        ? 'text-primary'
                        : 'text-mainBlack hover:text-primary'
                    }`}
                  >
                    <BsBriefcaseFill
                      size={20}
                      className={`mr-2 ${
                        selected === 'mission'
                          ? 'text-primary'
                          : 'text-primary hover:text-primary'
                      }`}
                    />
                    <span className="text-lg">Mes missions</span>
                  </button>
                </Link>
              </li>
              <li className="mb-8" onClick={() => handleSelection('client')}>
                <Link to="/client">
                  <button
                    className={`flex items-center ${
                      selected === 'client'
                        ? 'text-primary'
                        : 'text-mainBlack hover:text-primary'
                    }`}
                  >
                    <RiContactsBookFill
                      size={24}
                      className={`mr-2 ${
                        selected === 'client'
                          ? 'text-primary'
                          : 'text-primary hover:text-primary'
                      }`}
                    />
                    <span className="text-lg">Mes clients</span>
                  </button>
                </Link>
              </li>
              <li
                className="mb-8"
                onClick={() => handleSelection('declaration')}
              >
                <Link to="/declaration">
                  <button
                    className={`flex items-center ${
                      selected === 'declaration'
                        ? 'text-primary'
                        : 'text-mainBlack hover:text-primary'
                    }`}
                  >
                    <AiFillBank
                      size={24}
                      className={`mr-2 ${
                        selected === 'declaration'
                          ? 'text-primary'
                          : 'text-primary hover:text-primary'
                      }`}
                    />
                    <span className="text-lg">Déclaration</span>
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="px-4 py-2">
          <button
            className="flex items-center text-mainBlack hover:text-primary"
            onClick={() => {
              dispatch(logout());
              redirect('/');
            }}
          >
            Se déconnecter
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
