import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BiChevronUp, BiChevronDown } from 'react-icons/bi';

type DropDownResponsiveProps = {
  label: string;
  handleMenuClick: (label: string) => void;
  expandedLabel: string | null;
};

const DropDownMenuResponsive: React.FC<DropDownResponsiveProps> = ({
  label,
  expandedLabel,
  handleMenuClick,
}) => {
  const isExpanded = label === expandedLabel;
  let navLinks: JSX.Element[] = [];
  const navLinkClassName =
    'text-md text-gray-700 hover:text-gray-900 font-semibold hover:bg-gray-100 p-2 rounded-3xl transition duration-500';

  switch (label) {
    case 'Formules':
      navLinks = [
        <NavLink key="Offres" to="/tarification" className={navLinkClassName}>
          Offres
        </NavLink>,
        <NavLink
          key="Formulaire d'orientation"
          to="/guidance"
          className={navLinkClassName}
        >
          Orientation
        </NavLink>,
      ];
      break;
    case 'À propos':
      navLinks = [
        <NavLink
          key="Description"
          to="/description"
          className={navLinkClassName}
        >
          Description
        </NavLink>,
        <NavLink key="About" to="/about" className={navLinkClassName}>
          L&apos;équipe
        </NavLink>,
        <NavLink key="Timeline" to="/timeline" className={navLinkClassName}>
          Timeline
        </NavLink>,
      ];
      break;
    default:
      break;
  }

  return (
    <div>
      <div key={label}>
        <span
          className="text-black flex justify-between items-center cursor-pointer"
          onClick={() => handleMenuClick(label)}
        >
          <motion.p
            className={'text-lg font-medium'}
            transition={{ duration: 0.3 }}
          >
            {label}
          </motion.p>
          <span className={'transition-all duration-300'}>
            {isExpanded ? (
              <BiChevronUp size={26} style={{ color: 'black' }} />
            ) : (
              <BiChevronDown size={26} style={{ color: 'black' }} />
            )}
          </span>
        </span>
        {isExpanded && (
          <div className="responsive z-50 grid grid-row-4 gap-4 py-2 px-4">
            {navLinks}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDownMenuResponsive;
