import React from 'react';
import { MissionInfo } from '../globals';
import type { UserType } from '../globals';
import { useGetAllClientsQuery } from '../redux/services/clients';
type Props = {
  subtitle: string;
  text: string;
  data: MissionInfo;
  setData: any;
};

const GeneralInfo = ({ data, setData }: Props) => {
  const { data: clients, isLoading } = useGetAllClientsQuery({});

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedClientName = event.target.value;

    setData((prevData: MissionInfo) => ({
      ...prevData,
      client_id: parseInt(selectedClientName),
    }));
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setData({
      ...data,
      name: event.target.value,
    });
  };

  const handleStartingDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setData({
      ...data,
      start_date: event.target.value,
    });
  };

  const handleEndingDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setData({
      ...data,
      end_date: event.target.value,
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col bg-primary relative rounded-3xl lg:mx-8 md:mx-4 sm:mx-2 fixed-height-div">
      <div className="flex flex-col h-full p-4 justify-between">
        <div className="flex justify-center">
          <h1 className="text-white text-xl text-center my-4">
            Informations générales
          </h1>
        </div>
        <div className=" justify-center">
          <div className="bg-white mb-4 rounded-md p-2">
            Couverture: {data.formula}
          </div>
          <div className="bg-white mb-4 rounded-md p-2">
            <input
              className="w-full"
              type="text"
              placeholder="Nom de la mission"
              value={data.name}
              onChange={handleNameChange}
            />
          </div>
          <div className="bg-white mb-4 rounded-md p-2">
            <label className="text-gray-700 text-sm font-bold mb-2">
              Date de début de la mission
            </label>
            <input
              className="w-full"
              type="date"
              value={data.start_date}
              onChange={handleStartingDateChange}
            />
            <label className="text-gray-700 text-sm font-bold mb-2">
              Date de fin de la mission
            </label>
            <input
              className="w-full"
              type="date"
              value={data.end_date}
              onChange={handleEndingDateChange}
            />
          </div>
          <div className="bg-white mb-4 rounded-md p-2">
            <select
              value={data.client_id}
              onChange={handleChange}
              className="bg-white"
            >
              <option value="">Sélectionnez un client</option>
              {(clients as any).map((client: UserType) => (
                <option key={client.id} value={client.id}>
                  {client.first_name} {client.last_name}
                </option>
              ))}
            </select>
          </div>
          <div className="bg-white rounded-md">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="pl-2 py-2 text-left">
                    Estimation de votre CA
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2">Total (HT)</td>
                  <td className="px-4 py-2 text-right">
                    {data.sprints?.length &&
                      data.sprints.reduce(
                        (acc, sprint) => acc + sprint.price_ht,
                        0
                      )}
                    €
                  </td>
                </tr>
                <tr>
                  <td className="border-t border-gray-300 px-4 py-2">TVA</td>
                  <td className="border-t border-gray-300 px-4 py-2 text-right">
                    20%
                  </td>
                </tr>
                <tr>
                  <td className="border-t border-gray-300 px-4 py-2">
                    Frais Luter (
                    {data.formula === 'Green'
                      ? '2%'
                      : data.formula === 'Blue'
                      ? '7%'
                      : data.formula === 'Purple'
                      ? '11%'
                      : '0%'}
                    )
                  </td>
                  <td className="border-t border-gray-300 px-4 py-2 text-right">
                    {data.formula === 'Green'
                      ? (
                          (data.sprints?.length &&
                            data.sprints.reduce(
                              (acc, sprint) => acc + sprint.price_ht,
                              0
                            )) ||
                          0 * 0.02
                        ).toFixed(2)
                      : data.formula === 'Blue'
                      ? (
                          (data.sprints?.length &&
                            data.sprints.reduce(
                              (acc, sprint) => acc + sprint.price_ht,
                              0
                            )) ||
                          0 * 0.07
                        ).toFixed(2)
                      : data.formula === 'Purple'
                      ? (
                          (data.sprints?.length &&
                            data.sprints.reduce(
                              (acc, sprint) => acc + sprint.price_ht,
                              0
                            )) ||
                          0 * 0.11
                        ).toFixed(2)
                      : 0.0}
                    €
                  </td>
                </tr>
                <tr>
                  <td className="border-t border-gray-300 px-4 py-2">
                    Vous recevrez (TTC)
                  </td>
                  <td className="border-t border-gray-300 px-4 py-2 text-right">
                    {data.formula === 'Green'
                      ? (
                          (data.sprints?.length &&
                            data.sprints.reduce(
                              (acc, sprint) => acc + sprint.price_ht,
                              0
                            )) ||
                          0 * 0.78
                        ).toFixed(2)
                      : data.formula === 'Blue'
                      ? (
                          (data.sprints?.length &&
                            data.sprints.reduce(
                              (acc, sprint) => acc + sprint.price_ht,
                              0
                            )) ||
                          0 * 0.73
                        ).toFixed(2)
                      : data.formula === 'Purple'
                      ? (
                          (data.sprints?.length &&
                            data.sprints.reduce(
                              (acc, sprint) => acc + sprint.price_ht,
                              0
                            )) ||
                          0 * 0.69
                        ).toFixed(2)
                      : 0.0}
                    €
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
