import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Blocks } from 'react-loader-spinner';
export interface ModificationProposalDifferencesProps {
  initial: any;
  updated: any;
}

const fieldsName: { [key: string]: string } = {
  name: 'Nom',
  description: 'Description',
  start_date: 'Date de début',
  end_date: 'Date de fin',
  price: 'Prix',
  status: 'Statut',
  client_id: 'Client',
  freelancer_id: 'Freelancer',
  sprints: 'Sprints',
  projectUsers: 'Utilisateurs du projet',
  transactions: 'Transactions',
  projectPayments: 'Méthodes de paiement',
};

const fieldsToCompare: string[] = [
  'name',
  'description',
  'start_date',
  'end_date',
  'price',
  'sprints',
  'projectPayments',
];

const ModificationProposalDifferences = ({
  initial,
  updated,
}: ModificationProposalDifferencesProps) => {
  type NestedObject = { [key: string]: string | NestedObject };
  const [isLoading, setIsLoading] = useState(false);
  const [diff, setDiff] = useState<NestedObject>({});

  interface DifferenceObject {
    [key: string]: string | DifferenceObject;
  }

  const findDifferences = (
    obj1: NestedObject,
    obj2: NestedObject
  ): DifferenceObject => {
    setIsLoading(true);
    const differences: DifferenceObject = {};
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return {};
    }
    const compareObjects = (
      o1: NestedObject,
      o2: NestedObject,
      path: string[] = []
    ): void => {
      for (const key in o1) {
        if (!fieldsToCompare.includes(key)) {
          continue;
        }
        const currentPath = [...path, key];
        if (!(key in o2) || typeof o2[key] === undefined) {
          continue;
        }
        if (
          typeof o1[key] === 'object' &&
          o1[key] !== null &&
          typeof o2[key] === 'object' &&
          o2[key] !== null
        ) {
          compareObjects(
            o1[key] as NestedObject,
            o2[key] as NestedObject,
            currentPath
          );
        } else {
          if (o1[key] !== o2[key]) {
            differences[key] = o1[key];
          }
        }
      }
    };

    compareObjects(obj1, obj2);
    setIsLoading(false);
    return differences;
  };

  useEffect(() => {
    setDiff(findDifferences(initial, updated));
  }, [initial, updated]);

  return (
    <div>
      {isLoading ? (
        <div className="flex flex-col gap-4 justify-center items-center my-6">
          <p className="text-xl font-bold text-gray-900">
            Chargement des différences...
          </p>
          <Blocks color="#2563EB" height={50} width={50} />
        </div>
      ) : (
        Object.keys(diff).map((key, index) => {
          if (typeof diff[key] === undefined) {
            return null;
          }
          if (typeof diff[key] === 'object') {
            return (
              <div
                key={index}
                className="flex flex-col gap-2 justify-start items-start"
              >
                <h3 className="text-xl bold">{fieldsName[key] || key}:</h3>
                <div className="flex flex-col pl-6">
                  <p className="text-lg underline">Valeur initiale:</p>{' '}
                  {JSON.stringify(initial[key])}
                  <p className="text-lg underline">Valeur proposée:</p>{' '}
                  {JSON.stringify(updated[key])}
                </div>
              </div>
            );
          } else if (typeof diff[key] === 'string') {
            return (
              <div
                key={index}
                className="flex flex-col gap-2 justify-start items-start"
              >
                <h3 className="text-xl bold">{fieldsName[key] || key}:</h3>
                <div className="flex pl-6 gap-6 justify-center items-center">
                  <div>
                    <p className="text-lg underline">Valeur initiale:</p>{' '}
                    <p
                      className=" text-gray-900
                  text-opacity-80
                  line-through"
                    >
                      {initial[key]}
                    </p>
                  </div>
                  <FaArrowRight size={20} />
                  <div>
                    <p className="text-lg underline">Valeur proposée:</p>
                    <p className="text-gray-900 text-opacity-80">
                      {updated[key]}
                    </p>
                  </div>
                </div>
              </div>
            );
          }
        })
      )}
    </div>
  );
};

export default ModificationProposalDifferences;
