import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Navbar from '../../../Components/Navbar/Navbar';
import Footer from '../../../Components/Footer';
import { useSendEmailMutation } from '../../../redux/services/auth';
import displayWarningToast from '../../../utils/displayWarningToast';

const EmailRecovery = () => {
  const [email, setEmail] = useState('');
  const [sendEmail] = useSendEmailMutation();
  const navigate = useNavigate();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    sendEmail({ body: { email: email } })
      .unwrap()
      .then((res: any) => {
        navigate('/email-sent');
      })
      .catch((err: any) => {
        console.error(
          "Erreur lors de l'envoi de l'e-mail de réinitialisation :",
          err
        );
        if (err.status === 400)
          displayWarningToast(err.data.error, 'sendEmailFailed');
      })
      .finally(() => {
        //setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Navbar />
      <div className="flex flex-col justify-center items-center gap-10 py-40">
        <h2 className="text-2xl font-semibold mb-4">
          Récupération du mot de passe
        </h2>
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-80"
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Adresse e-mail
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Entrez votre adresse e-mail"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <p className="block text-gray-700 text-sm mb-5">
            Entrez un mail valide pour recevoir un lien de réinitialisation.
          </p>
          <div className="flex items-center justify-center">
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Suivant
            </button>
          </div>
        </form>
        <p className="text-gray-600">
          Pas encore de compte?{' '}
          <Link to="/register" className="text-blue-500 hover:underline">
            S&apos;inscrire
          </Link>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default EmailRecovery;
