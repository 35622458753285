import React from 'react';
import clsx from 'clsx';
import { IconType } from 'react-icons';

type Props = {
  children: React.ReactNode;
  onClick?: (e: any) => void;
  variant?: 'secondary' | 'primary';
  className?: string;
  basicCase?: boolean;
  type?: string;
  disabled?: boolean;
  Icon?: IconType;
};

const ButtonModern = ({
  children,
  onClick,
  variant = 'primary',
  className,
  basicCase = false,
  Icon,
}: Props) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        variant === 'primary' &&
          clsx(
            !basicCase && 'uppercase',
            'bg-white shadow-none border-black border-2 transition-all duration-300 hover:shadow-[-5px_5px_0px_1px] text-dark p-4 rounded-md text-xl md:text-2xl flex items-center justify-center'
          ),
        variant === 'secondary' &&
          clsx(
            !basicCase && 'uppercase',
            'shadow-none border-white border-2 transition-all duration-300 hover:shadow-[-5px_5px_0px_1px] text-white p-4 rounded-md text-xl md:text-2xl flex items-center justify-center'
          ),
        className
      )}
    >
      {Icon && <Icon className="" />}
      {children}
    </button>
  );
};

export default ButtonModern;
