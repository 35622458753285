import React from 'react';
type Props = {
  children: any;
};
import FreelanceNavbar from '../Components/FreelanceNavbar';
import Sidebar from '../Components/Sidebar';
import Chatbot from '../Components/Chatbot/Chatbot';
import { useSelector } from 'react-redux';

const DashboardLayout: React.FC<Props> = ({ children }) => {
  const user = useSelector((state: any) => state.user.user);
  const username = `${user?.first_name} ${user?.last_name}`;

  return (
    <main className="min-h-screen z-0 bg-creamWhite h-full">
      <FreelanceNavbar />
      <div className="flex justify-start w-full pb-8">
        <Sidebar username={username} />
        <div className="mt-32 w-full pr-8 h-ful pl-8 lg:ml-0">{children}</div>
      </div>
      <Chatbot />
    </main>
  );
};

export default DashboardLayout;
