import React from 'react';
import { Signature } from '../../globals';
import moment from 'moment';

interface SignatureProps {
  signature: Signature;
}

const DocumentSignature = ({ signature }: SignatureProps) => {
  return (
    <div>
      <img src={signature.imageUrl} alt="signature" />
      <i>{moment(signature.signedAt).format('DD/MM/YYYY')}</i>
    </div>
  );
};

export default DocumentSignature;
