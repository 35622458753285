import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { autoLogin } from '../redux/feature/userSlice';
import { useGetUserAutoLoginMutation } from '../redux/services/auth';

export const AutoLoginRedirect = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [getUserAutoLogin] = useGetUserAutoLoginMutation();

  async function fetchUser() {
    let token = searchParams.get('token');
    const res: any = await getUserAutoLogin(token);
    dispatch(
      autoLogin({ user: res.data.user, accessToken: searchParams.get('token') })
    );
  }
  useEffect(() => {
    fetchUser();
    setTimeout(() => {
      window.location.href = '/dashboard';
    }, 1000);
  }, [dispatch, searchParams]);

  return null;
};
