import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BiMenu, BiX } from 'react-icons/bi';
import LogoBlack from '../../assets/logo_black_text_black.svg';
import DropDownMenuResponsive from './NavbarDropDownResponsive';

const ResponsiveNavbar = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [expandedLabel, setExpandedLabel] = useState<string | null>(null);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    setExpandedLabel(null);
  };

  const handleMenuClick = (label: string) => {
    if (expandedLabel === label) {
      setExpandedLabel(null);
    } else {
      setExpandedLabel(label);
      setIsMenuOpen(true);
    }
  };

  return (
    <motion.nav
      className={`fixed top-0 left-0 ${
        isMenuOpen ? 'w-full h-full bg-white' : 'bg-white w-full'
      } z-50`}
    >
      <div className="container mx-auto px-4 py-4 flex flex-col justify-between items-center h-full">
        <div className="flex justify-between w-full items-center">
          <a href="/">
            <motion.img
              src={LogoBlack}
              alt="logo"
              className="w-32"
              transition={{ duration: 0.3 }}
            />
          </a>
          <button
            className="focus:outline-none xl:hidden px-4"
            onClick={handleMenuToggle}
          >
            {isMenuOpen ? (
              <BiX size={32} style={{ color: 'black' }} />
            ) : (
              <BiMenu size={32} style={{ color: 'black' }} />
            )}
          </button>
        </div>
        <div
          className={`flex ${
            isMenuOpen ? 'flex-row ' : 'hidden'
          } absolute space-x-20 top-28 left-10 z-90`}
        >
          <DropDownMenuResponsive
            label="Formules"
            expandedLabel={expandedLabel}
            handleMenuClick={handleMenuClick}
          />
          <DropDownMenuResponsive
            label="À propos"
            expandedLabel={expandedLabel}
            handleMenuClick={handleMenuClick}
          />
        </div>
        <div className="flex flex-col items-center mb-8 border-t-2">
          <button
            className={`${
              isMenuOpen ? 'flex-row' : 'hidden'
            } text-xl px-4 mb-4 mt-4 font-semibold py-2 rounded-md focus:outline-none hover:opacity-80 transition-all text-black hover:text-gray-800`}
            onClick={() => navigate('/login')}
            data-testid="sign-in-button"
          >
            Se connecter
          </button>
          <button
            className={`${
              isMenuOpen ? 'flex-row' : 'hidden'
            } text-xl border-2 hover:opacity-80 font-semibold py-2 px-4 transition-all rounded-lg min-w-fit border-black text-black hover:text-gray-800`}
            onClick={() => navigate('/register')}
            data-testid="sign-up-button"
          >
            S&apos;inscrire
          </button>
        </div>
      </div>
    </motion.nav>
  );
};

export default ResponsiveNavbar;
