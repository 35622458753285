import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ProgressBar from '../ProgressBar';
import BulleForm from '../BulleForm';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa6';

import { useNavigate } from 'react-router-dom';

import { type SignUpFormFreelancer } from '../../globals';

import { useSignUpFreelanceMutation } from '../../redux/services/auth';

const RegisterFormInfo = ({ onNext, onPrev, data, setData }: any) => {
  const [signUp] = useSignUpFreelanceMutation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const [passwordCriteria, setPasswordCriteria] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasDigit: false,
    hasSpecialChar: false,
    isLongEnough: false,
  });

  const handlePasswordChange = (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isLongEnough = password.length >= 8;
    setPasswordCriteria({
      hasUpperCase,
      hasLowerCase,
      hasDigit,
      hasSpecialChar,
      isLongEnough,
    });
    setData({ ...data, password });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (data.password !== data.confirmPassword) {
      toast.warn('Les mots de passe ne correspondent pas', {
        toastId: 'passwordsDontMatch',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setData({ ...data, loading: false });
      return;
    }
    setData({ ...data, loading: true });
    return new Promise<string>((resolve, reject) => {
      const formData: SignUpFormFreelancer = {
        address: data.address,
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password: data.password,
        phone: data.phone,
        siret: data.siret,
        tva_number: data.tva,
        company_name: data.society,
        white_label: data.whiteLabel,
        companyAddress: {
          line1: data.address,
          city: data.city,
          postal_code: data.zip,
          country: data.country,
        },
        dob: {
          day: data.dob.day,
          month: data.dob.month,
          year: data.dob.year,
        },
        url: data.url,
        mcc: data.mcc,
        business_type: data.business_type,
      };
      signUp({ body: formData })
        .unwrap()
        .then((res: any) => {
          setData({ ...data, loading: false });
          navigate('/login');
          resolve('Success');
        })
        .catch((err: any) => {
          console.log(err);
          if (err.status === 400) {
            toast.warn(err.data.error, {
              toastId: 'badEmailOrUserAlreadyExists',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
          setData({ ...data, loading: false });
          reject(err.data.error ? err.data.error : err);
        });
    });
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone: string): boolean => {
    const phoneRegex = /^\+\d{11}$/;
    return phoneRegex.test(phone);
  };

  const isValidPassword = (password: string): boolean => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  const onNextClick = () => {
    const isEmailValid = isValidEmail(data.email);
    const isPhoneValid = isValidPhone(data.phone);
    const isPasswordValid = isValidPassword(data.password);

    if (!isEmailValid) {
      toast.warn('Veuillez entrer une adresse e-mail valide.', {
        toastId: 'invalidEmail',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    if (!isPhoneValid) {
      toast.warn('Veuillez entrer un numéro de téléphone valide.', {
        toastId: 'invalidPhone',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    if (!isPasswordValid) {
      toast.warn(
        'Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre.',
        {
          toastId: 'invalidPassword',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
      return;
    }
    onNext();
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex min-h-screen overflow-hidden p-20 items-center">
      {windowWidth > 768 && (
        <div className="w-full md:w-2/5 pl-4 pr-10" style={{ height: '100%' }}>
          <BulleForm
            title="Aucun engagement !"
            subtitle="Utilisation des données personnelles"
            text="Vos données sont sécrurisées et ne seront jamais partagées à des tiers. Vous pouvez consulter notre politique de confidentialité pour plus d'informations."
            buttonText="Politique de confidentialité"
            buttonLink="/politique-confidentialite"
          />
        </div>
      )}
      <div className="pr-10 w-full flex flex-col h-full justify-center items-center">
        <ProgressBar percent={33} />
        <div className="p-2 w-full flex flex-col h-full justify-center items-center mt-8">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col w-full md:w-2/3 rounded-md pl-10"
          >
            <div className="w-full">
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="email"
                >
                  E-mail
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="email"
                  type="email"
                  value={data.email}
                  placeholder="nom@domaine.com"
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="phone"
                >
                  Numéro de téléphone
                </label>
                <div className="flex items-center">
                  <span className="bg-blue-500 text-white border p-2 rounded-l-md font-bold">
                    +33
                  </span>
                  <input
                    type="text"
                    value={data.phone.replace('+33', '')}
                    placeholder="642424242"
                    onChange={(e) => {
                      if (e.target.value.length > 9) return;

                      setData({ ...data, phone: '+33' + e.target.value });
                    }}
                    className={`shadow appearance-none border rounded-r-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      !isValidEmail ? 'border-red-500' : ''
                    }`}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="password"
                >
                  Mot de passe
                </label>
                <div className="relative">
                  <input
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      !isValidPassword(data.password) ? 'border-red-500' : ''
                    }`}
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="AHBi4@2!"
                    value={data.password}
                    onChange={(e) => handlePasswordChange(e.target.value)}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                    onClick={handlePasswordVisibility}
                  >
                    {showPassword ? (
                      <FaRegEyeSlash className="h-6 w-6" />
                    ) : (
                      <FaRegEye className="h-6 w-6" />
                    )}
                  </button>
                </div>
                <ul className="text-sm mt-2">
                  <li
                    className={`${
                      passwordCriteria.hasUpperCase
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}
                  >
                    Contient une majuscule
                  </li>
                  <li
                    className={`${
                      passwordCriteria.hasLowerCase
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}
                  >
                    Contient une minuscule
                  </li>
                  <li
                    className={`${
                      passwordCriteria.hasDigit
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}
                  >
                    Contient un chiffre
                  </li>
                  <li
                    className={`${
                      passwordCriteria.hasSpecialChar
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}
                  >
                    Contient un caractère spécial
                  </li>
                  <li
                    className={`${
                      passwordCriteria.isLongEnough
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}
                  >
                    Contient au moins 8 caractères
                  </li>
                </ul>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="confirmPassword"
                >
                  Confirmez le mot de passe
                </label>
                <div className="relative">
                  <input
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    id="confirmPassword"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="AHBi4@2!"
                    value={data.confirmPassword}
                    onChange={(e) =>
                      setData({ ...data, confirmPassword: e.target.value })
                    }
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                    onClick={handlePasswordVisibility}
                  >
                    {showPassword ? (
                      <FaRegEyeSlash className="h-6 w-6" />
                    ) : (
                      <FaRegEye className="h-6 w-6" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-12">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={onNextClick}
              >
                Suivant
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterFormInfo;
