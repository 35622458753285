import React from 'react';
import { IconType } from 'react-icons';
import { motion } from 'framer-motion';

type PricingCardProps = {
  title: string;
  description: string;
  price: string;
  offre: string;
  advantages: { text: string; icon: IconType }[];
  ctaLabel: string;
  ctaOnClick: () => void;
  // background: string;
  color: string;
};

const PricingCard = ({
  title,
  description,
  price,
  offre,
  advantages,
  ctaLabel,
  ctaOnClick,
  // background,
  color,
}: PricingCardProps) => {
  const listItemVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  const buttonVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
  };

  return (
    <div
      className="rounded-lg shadow-lg transition-shadow duration-300 hover:shadow-xl p-6 space-y-4 w-[400px] border border-gray-300 border-opacity-50"
      // style={{ background }}
    >
      <motion.h2
        className="text-4xl font-semibold mb-5 font-extrabold text-center"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        style={{ color: color }}
      >
        {title}
      </motion.h2>
      <motion.p
        className="font-semibold mb-5 text-center"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.4 }}
        style={{ color: color }}
      >
        {description}
      </motion.p>
      <div className="flex items-center justify-center mb-4">
        <motion.span
          className="text-6xl font-bold mr-2"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3, delay: 0.6 }}
          style={{ color: color }}
        >
          {price}
        </motion.span>
        <span className="text-white">/mission</span>
      </div>
      <motion.p
        className="mb-5 text-sm text-center"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.4 }}
        style={{ color: color }}
      >
        {offre}
      </motion.p>
      <ul className="mb-4">
        {advantages.map((advantage, index) => (
          <motion.li
            key={index}
            className="flex items-center font-semibold space-x-5 my-6"
            variants={listItemVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3, delay: 0.2 + index * 0.1 }}
          >
            <span
              className="rounded-full w-8 h-8 flex items-center justify-center"
              style={{ backgroundColor: color }}
            >
              {advantage.icon({ size: 18, color: 'white' })}
            </span>
            <span>{advantage.text}</span>
          </motion.li>
        ))}
      </ul>
      <motion.button
        className="text-lg font-semibold rounded-lg py-3 transition-colors duration-300 w-full text-white"
        onClick={ctaOnClick}
        variants={buttonVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.3, delay: 1 }}
        style={{ backgroundColor: color }}
      >
        {ctaLabel}
      </motion.button>
    </div>
  );
};

export default PricingCard;
