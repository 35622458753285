import React from 'react';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import Spline from '@splinetool/react-spline';

const Dashboard = () => {
  return (
    <section className="relative z-10 h-auto pt-72 mb-56" id="dashboard">
      <InView trackVisibility delay={500}>
        {({ inView, ref, entry }) => (
          <motion.div
            className="grid xs:grid-cols-1 xl:grid-cols-4 gap-48"
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <div className="col-span-1"></div>
            <div className="col-span-1 flex justify-center items-center xl:text-right xs:text-center">
              <div className="flex flex-col">
                <div
                  className={`flex font-Apfel flex-col gap-2 ${
                    inView ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <motion.h1
                    className="text-5xl font-black"
                    initial={{ opacity: 0, y: 20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.3, delay: 0.2 }}
                    dangerouslySetInnerHTML={{
                      __html:
                        "Utilisation simple et <span class='relative'><span id='handDrawnScribble' class='relative z-10'>intuitive</span></span>",
                    }}
                  ></motion.h1>
                  <motion.p
                    className="text-xl"
                    initial={{ opacity: 0, y: 20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.3, delay: 0.4 }}
                  >
                    Laissez-vous guider par notre interface moderne.
                  </motion.p>
                </div>
              </div>
            </div>
            <div className="xs:hidden md:grid col-span-1 place-content-center">
              <motion.div
                style={{ marginTop: 'auto', marginBottom: 'auto' }}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={inView ? { opacity: 1, scale: 1 } : {}}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <Spline scene="https://prod.spline.design/IVJpEmVVGS9qTjuU/scene.splinecode" />
              </motion.div>
            </div>
            <div className="col-span-1 xs:hidden xl:flex"></div>
          </motion.div>
        )}
      </InView>
    </section>
  );
};

export default Dashboard;
