import React, { FormEvent, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useAddClientMutation } from '../../redux/services/clients';
import displaySuccessToast from '../../utils/displaySuccessToast';
import displayWarningToast from '../../utils/displayWarningToast';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserAutoLoginMutation } from '../../redux/services/auth';
import { RootState } from '../../redux/store';

const initialClient = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  siret: '',
  tva_number: '',
  company_name: '',
  address: '',
};

const validateEmail = (email: string) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
const validatePhone = (phone: string) => /^\d{10}$/.test(phone);
const validateSiret = (siret: string) => /^\d{14}$/.test(siret);
const validateTvaNumber = (tva_number: string) =>
  /^[A-Z]{2}\d{11}$/.test(tva_number);

function getPlaceholder(field: string) {
  switch (field) {
    case 'email':
      return 'exemple@domaine.com';
    case 'phone':
      return '+33 6 12 34 56 78';
    case 'siret':
      return '123 456 789 01234';
    case 'tva_number':
      return 'FR 12345678901';
    case 'first_name':
      return 'Prénom';
    case 'last_name':
      return 'Nom';
    case 'company_name':
      return "Nom de l'entreprise";
    default:
      return `Entrez ${field}`;
  }
}

function getFormatHelpText(field: string) {
  switch (field) {
    case 'email':
      return 'Format attendu: exemple@domaine.com';
    case 'phone':
      return 'Format attendu: 10 chiffres sans espaces';
    case 'siret':
      return 'Format attendu: 14 chiffres';
    case 'tva_number':
      return 'Format attendu: 2 lettres suivies de 11 chiffres';
    default:
      return '';
  }
}

const AddClient = () => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state?.user?.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [addClient, response] = useAddClientMutation();
  const [editableClient, setEditableClient] = useState(initialClient);
  const [getUserAutoLogin] = useGetUserAutoLoginMutation();

  const handleInputChange = (fieldName: string, value: string) => {
    setEditableClient((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const isFormValid = () => {
    return (
      editableClient.first_name &&
      editableClient.last_name &&
      validateEmail(editableClient.email) &&
      validatePhone(editableClient.phone) &&
      validateSiret(editableClient.siret) &&
      validateTvaNumber(editableClient.tva_number) &&
      editableClient.company_name &&
      editableClient.address
    );
  };

  const handleCreate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isFormValid()) {
      displayWarningToast(
        'Veuillez remplir correctement tous les champs requis.',
        'errorCreatingClient'
      );
      return;
    }

    setIsSubmitting(true);

    try {
      await addClient({ body: editableClient })
        .unwrap()
        .then((res) => {
          displaySuccessToast(
            'Client créé avec succès',
            'successCreatingClient'
          );
          navigate('/client');
        })
        .catch((err) => {
          console.error('Error adding client:', err);
          displayWarningToast(
            'Erreur lors de la création du client',
            'errorCreatingClient'
          );
        });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <form
        onSubmit={handleCreate}
        className="p-4 md:p-8 lg:p-12 bg-white rounded-lg shadow-xl"
      >
        <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
          <h2 className="text-mainBlack text-xl font-semibold">
            Créer un client
          </h2>
          <Link
            to="/client"
            className="transition-colors duration-300 transform hover:bg-blue-500 bg-blue-600 rounded-full text-white flex items-center justify-center px-4 py-2"
          >
            <AiOutlineArrowLeft className="mr-2" size={24} />
            Retour
          </Link>
        </div>
        <div className="space-y-6">
          {Object.entries(editableClient).map(([field, value]) => (
            <div key={field} className="flex flex-col">
              <label
                htmlFor={field}
                className="text-lg font-medium text-gray-700"
              >
                {/* {field
                  .split('_')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')} */}
                {field === 'first_name' && 'Prénom'}
                {field === 'last_name' && 'Nom'}
                {field === 'email' && 'Email'}
                {field === 'phone' && 'Téléphone'}
                {field === 'siret' && 'SIRET'}
                {field === 'tva_number' && 'Numéro de TVA'}
                {field === 'company_name' && "Nom de l'entreprise"}
                {field === 'address' && 'Adresse'}:
              </label>
              <input
                id={field}
                type="text"
                placeholder={getPlaceholder(field)}
                value={value}
                className="mt-1 p-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all"
                onChange={(e) => handleInputChange(field, e.target.value)}
                required
              />
              <p className="mt-1 text-sm text-gray-600">
                {getFormatHelpText(field)}
              </p>
              {field === 'email' && !validateEmail(value) && value && (
                <p className="mt-1 text-sm text-red-500">
                  Veuillez entrer une adresse email valide.
                </p>
              )}
              {field === 'phone' && !validatePhone(value) && value && (
                <p className="mt-1 text-sm text-red-500">
                  Veuillez entrer un numéro de téléphone valide (10 chiffres).
                </p>
              )}
              {field === 'siret' && !validateSiret(value) && value && (
                <p className="mt-1 text-sm text-red-500">
                  Veuillez entrer un numéro SIRET valide (14 chiffres).
                </p>
              )}
              {field === 'tva_number' && !validateTvaNumber(value) && value && (
                <p className="mt-1 text-sm text-red-500">
                  Veuillez entrer un numéro de TVA valide (2 lettres suivies de
                  11 chiffres).
                </p>
              )}
            </div>
          ))}
        </div>
        <div className="mt-10 flex justify-center">
          <button
            type="submit"
            disabled={!isFormValid() || isSubmitting}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? 'En cours de création...' : 'Créer'}
          </button>
        </div>
      </form>
    </DashboardLayout>
  );
};

export default AddClient;
