import React, { useState } from 'react';

interface ToggleSwitcherProps {
  enabled?: boolean;
  setEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  readonly?: boolean;
}

const ToggleSwitcher: React.FC<ToggleSwitcherProps> = ({
  readonly,
  enabled,
  setEnabled,
}) => {
  return (
    <div className="relative inline-block w-11 h-6">
      {!readonly ? (
        <>
          <input
            type="checkbox"
            className="sr-only peer"
            checked={enabled}
            readOnly
          />
          <div
            onClick={() => {
              setEnabled && setEnabled(!enabled);
            }}
            className="w-full h-full bg-gray-200 rounded-full peer peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
          ></div>
        </>
      ) : (
        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-blue-300 after:content-[''] after:absolute after:top-0.5 after:left-[22px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5"></div>
      )}
    </div>
  );
};

export default ToggleSwitcher;
