import React, { useState } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import RegisterFormInfo from '../../Components/RegisterFormFreelance/RegisterFormInfo';
import RegisterFormInfo2 from '../../Components/RegisterFormFreelance/RegisterFormInfo2';
import RegisterFormInfo3 from '../../Components/RegisterFormFreelance/RegisterFormInfo3';

const Register = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState({
    phone: '',
    email: '',
    siret: '',
    tva: '',
    society: '',
    whiteLabel: false,
    zip: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    address: '',
    loading: false,
  });

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderFormStep = (step: number) => {
    switch (step) {
      case 1:
        return (
          <RegisterFormInfo
            onPrev={goToPreviousStep}
            onNext={goToNextStep}
            data={data}
            setData={setData}
          />
        );
      case 2:
        return (
          <RegisterFormInfo2
            onPrev={goToPreviousStep}
            onNext={goToNextStep}
            data={data}
            setData={setData}
          />
        );
      case 3:
        return (
          <RegisterFormInfo3
            onPrev={goToPreviousStep}
            data={data}
            setData={setData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Navbar />
      {renderFormStep(currentStep)}
    </div>
  );
};

export default Register;
