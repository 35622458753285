import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
// Icon

// Components
import SettingsMenu from '../../Components/Dashboard/SettingsMenu';
import Button from '../../Components/Button';
import DashboardLayout from '../../Layout/DashboardLayout';
import SettingsMenuSmall from '../../Components/Dashboard/SettingsMenuSmall';
import ToggleSwitcher from '../../Components/molecules/ToggleSwitcher';

const SettingsPrivacy: React.FC = () => {
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);

  return (
    <DashboardLayout>
      <div className="flex">
        <div className="w-full p-4">
          <div className="flex flex-row mb-10">
            <h1 className="text-left text-4xl font-bold bold-4 w-1/2 text-left">
              Paramètres du profil
            </h1>
            {/* <div className="w-1/2 text-right">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => navigate('/profile')}
              >
                Voir votre profil publique
              </button>
            </div> */}
          </div>
          <div className="md:hidden xs:block w-1/4 pr-4">
            <SettingsMenuSmall />
          </div>
          <div className="flex overflow-y-auto p-6 xs:-mr-8 md:mr-0">
            <div className="md:block xs:hidden w-1/4 pr-4">
              <SettingsMenu />
            </div>
            <div className="xs:hidden md:block w-px bg-gray-300 mx-4" />

            <div className="relative w-full overflow-y-auto border-2 rounded-[5px] p-4">
              <div className="absolute top-0 left-0 w-full h-full bg-gray-200 bg-opacity-75 flex items-center justify-center rounded-[5px] z-50">
                <span className="text-gray-700 font-bold text-2xl">
                  Bientôt disponible
                </span>
              </div>
              <div className="flex items-center justify-between">
                <h1 className="font-bold text-3xl mt-4">Confidentialité</h1>
              </div>
              <h3 className="text-blue-500 ml-5">
                Nous gardons vos données privées et ne les partageons pas avec
                des parties tier
              </h3>
              <hr className="my-2" />
              <div className="flex mt-10">
                <div className="space-y-5 text-left mx-20">
                  <div className="items-center cursor-pointer mb-5">
                    <ToggleSwitcher enabled={enabled} setEnabled={setEnabled} />
                    <p className="flex -mt-7 ml-16 text-black font-bold">
                      Apparaître dans les moteurs de recherche
                    </p>
                  </div>
                  <p className="text-black text-sm">
                    Si vous décochez cette case, votre profil risque de perdre
                    en visibilité sur la plateforme. En effet, cela entraînera
                    une invisibilité de votre profil sur les moteurs de
                    recherche tels que Google, Bing, et sur le moteur de
                    recherche interne de Luter pour les utilisateurs non
                    connectés.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SettingsPrivacy;
