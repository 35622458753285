import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';
import PDFViewer from '../../Components/molecules/PDFViewer';
import { Blocks } from 'react-loader-spinner';
import displaySuccessToast from '../../utils/displaySuccessToast';
import { useAddDocumentMutation } from '../../redux/services/missions';

const MissionNewDocument = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [addDocument] = useAddDocumentMutation();
  const [clientSignatureRequired, setClientSignatureRequired] =
    useState<boolean>(false);

  const handleUpload = async () => {
    setLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);
    reader.onloadend = async () => {
      const base64File = reader.result;
      await addDocument({
        body: {
          formData: base64File,
          projectId: parseInt(id || ''),
          fileName: name,
          clientSignatureRequired,
        },
      });
      setTimeout(() => {
        displaySuccessToast('Document ajouté avec succès');
        setLoading(false);
        navigate(`/mission/${id}/documents`);
      }, 1000);
    };
  };

  return (
    <DashboardLayout>
      <h1 className="text-3xl font-bold text-gray-900">
        Ajouter un document à la mission
      </h1>
      <div className="mt-6 flex flex-col gap-4">
        <div className="flex gap-4 w-full">
          <label className="flex flex-col gap-2 w-full">
            <span className="text-sm font-medium text-gray-900">
              Nom du document
            </span>
            <input
              type="text"
              onChange={(e) => setName(e.target.value)}
              className="border shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md p-2"
            />
          </label>
        </div>
        <div className="flex gap-4">
          <label className="flex flex-col gap-2 w-full">
            <span className="text-sm font-medium text-gray-900">Fichier</span>
            <input
              type="file"
              accept="application/pdf,application/vnd.ms-excel"
              onChange={(e) => {
                setFile(e.target.files?.[0] || null);
              }}
              className="border shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md p-2"
            />
          </label>
        </div>
        <div className="flex gap-4">
          <label className="flex flex-col gap-2 w-full">
            <span className="text-sm font-medium text-gray-900">
              Demander une signature au client ?
            </span>
            <select
              onChange={(e) =>
                setClientSignatureRequired(e.target.value === 'true')
              }
              className="border shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md p-2"
            >
              <option value="false" defaultChecked>
                Non
              </option>
              <option value="true">Oui</option>
            </select>
          </label>
        </div>
        <div className="flex gap-4">
          <button
            onClick={() => navigate(`/mission/${id}/documents`)}
            className="flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-500 hover:bg-gray-700"
          >
            Annuler
          </button>
          <button
            onClick={handleUpload}
            className="flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700
              disabled:opacity-50 disabled:cursor-not-allowed
            "
            disabled={!file || !name}
          >
            {loading && (
              <Blocks color="#FFF" height={24} width={24} visible={loading} />
            )}
            {!loading && <span>Enregistrer</span>}
          </button>
        </div>
        <h2 className="text-2xl font-bold text-gray-900">Aperçu</h2>
        <div className="my-6 bg-white rounded-md w-full h-full p-4">
          {file && <PDFViewer file={file} />}
          {!file && <p className="text-center">Aucun fichier sélectionné</p>}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MissionNewDocument;
