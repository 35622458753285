import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../Layout/DashboardLayout';
import Tooltip from '../../Components/molecules/Tooltip';
import { Blocks } from 'react-loader-spinner';
import numberFormatter from '../../utils/numberFormatter';
import Button from '../../Components/Button';
import RadioField from '../../Components/molecules/RadioField';

type Props = {};

const PriceInput = (props: {
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="flex items-center gap-3 p-2">
      <input
        className="border border-gray-300 rounded p-2"
        type="number"
        name="income"
        id="price"
        value={props.value}
        onChange={props.onChange}
      />
      <label htmlFor="price">€</label>
    </div>
  );
};

const DeclarationSimulatorScreen = (props: Props) => {
  const [simulationData, setSimulationData] = useState<any>({
    income: 0,
    period: 'MONTH',
    tax: 0,
    acre: 'false',
  });

  const [result, setResult] = useState<any>({
    taxes: 0,
    netIncome: 0,
    completed: false,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSimulationData({
      ...simulationData,
      [e.target.name]: e.target.value,
    });
    setResult({
      taxes: 0,
      netIncome: 0,
      completed: false,
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setResult({
      taxes: 0,
      netIncome: 0,
      completed: false,
    });
    const taxes =
      parseFloat(simulationData.income) *
      (simulationData.acre === 'true' ? 0.11 : 0.22 + 0.001);
    const netIncome = parseFloat(simulationData.income) - taxes;
    setTimeout(() => {
      setLoading(false);
      setResult({
        taxes: taxes,
        netIncome: netIncome,
        completed: true,
      });
    }, 2000);
  };

  useEffect(() => {
    setSimulationData({
      ...simulationData,
      income:
        simulationData.period === 'MONTH'
          ? simulationData.income
          : simulationData.period === 'QUARTER'
          ? simulationData.income * 3
          : simulationData.income * 12,
    });
  }, [simulationData.period]);

  return (
    <DashboardLayout>
      <div className="min-h-[79vh] p-6 flex flex-col gap-6">
        <h1 className="text-2xl font-semibold">Simulateur de déclaration</h1>
        <div className="p-4">
          <div className="flex flex-col gap-4">
            <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">
              Sélectionnez une période
            </h3>
            <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
              <RadioField
                name="period"
                label="Mois"
                id="month"
                value="MONTH"
                checked={simulationData.period === 'MONTH'}
                onChange={(e) => handleChange(e)}
              />
              <RadioField
                name="period"
                label="Trimestre"
                id="quarter"
                value="QUARTER"
                checked={simulationData.period === 'QUARTER'}
                onChange={(e) => handleChange(e)}
              />
              <RadioField
                name="period"
                label="Année"
                id="year"
                value="YEAR"
                checked={simulationData.period === 'YEAR'}
                onChange={(e) => handleChange(e)}
              />
            </ul>
            <h3 className="text-xl font-bold">Votre chiffre d&apos;affaire</h3>
            <p className="text-sm text-gray-600">
              Votre chiffre d&apos;affaire est le montant total de vos ventes de
              biens ou de services réalisées au cours de l&apos;année.
            </p>
            <PriceInput
              value={simulationData.income}
              onChange={(e) => handleChange(e)}
            />
            <h3 className="text-xl font-bold">Acre</h3>
            <div className="flex relative gap-2 items-center">
              <p className="text-sm text-gray-600">
                Précisez si vous bénéficiez actuellement de l&apos;Acre
              </p>
              <Tooltip tooltipText="L'ACRE (Aide aux créateurs et repreneurs d'entreprise) est une aide financière de l'État qui permet aux entrepreneurs de bénéficier d'une exonération partielle de leurs cotisations sociales pendant les 12 premiers mois d'activité." />
            </div>
            <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
              <RadioField
                name="acre"
                label="Oui"
                id="acre-yes"
                value={'true'}
                checked={simulationData.acre === 'true'}
                onChange={(e) => handleChange(e)}
              />
              <RadioField
                name="acre"
                label="Non"
                id="acre-no"
                value={'false'}
                checked={simulationData.acre === 'false'}
                onChange={(e) => handleChange(e)}
              />
            </ul>
          </div>
        </div>
        {result.completed && (
          <div className="flex flex-col gap-4">
            <h1 className="text-2xl font-bold">Résultats</h1>
            <div className="p-4">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col justify-between">
                  <div className="flex justify-between">
                    <p className="font-bold">Impôts sur le revenu</p>
                    <p className="font-bold">
                      {numberFormatter(result.taxes || 0)} €
                    </p>
                  </div>
                  <div className="w-full h-px bg-black rounded-md my-2"></div>
                  <div className="ml-4 flex justify-between">
                    <div className="flex relative gap-2 items-center">
                      <p>Cotisations sociales</p>
                      <Tooltip tooltipText="Les cotisations sociales sont des prélèvements obligatoires effectués sur les revenus des travailleurs indépendants. Elles permettent de financer la protection sociale des travailleurs indépendants." />
                    </div>
                    <p className="font-bold text-red-500">
                      {parseFloat(simulationData.income) *
                        (simulationData.acre === 'true' ? 0.11 : 0.22) || 0}
                      € {simulationData.acre === 'false' ? '-22%' : '-11%'}
                    </p>
                  </div>
                  {simulationData.acre === 'false' && (
                    <div className="ml-4 flex justify-between">
                      <div className="flex relative gap-2 items-center">
                        <p>Contributions CFP</p>
                        <Tooltip tooltipText="La contribution à la formation professionnelle (CFP) est une cotisation obligatoire versée par les travailleurs indépendants. Elle permet de financer les formations professionnelles des travailleurs indépendants et des demandeurs d'emploi." />
                      </div>
                      <p className="font-bold text-red-500">
                        {parseFloat(simulationData.income) * 0.001 || 0}€
                        (-0,1%)
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex justify-between">
                  <p className="font-bold">Revenu net</p>
                  <p className="font-bold">
                    {numberFormatter(result.netIncome || 0)} €
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {loading && (
          <div className="flex justify-center">
            <Blocks color="#2850E7" />
          </div>
        )}
        <div className="flex justify-end">
          <Button
            disabled={
              loading ||
              simulationData.period === '' ||
              simulationData.income === '' ||
              simulationData.acre === ''
            }
            onClick={onSubmit as any}
            primary
          >
            Lancer la simulation
          </Button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DeclarationSimulatorScreen;
