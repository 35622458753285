import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FaChevronLeft, FaSave } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';

const UpdateMission = () => {
  const { id } = useParams();
  const location = useLocation();
  const [state, setState] = useState(location.state);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data: { [key: string]: any } = {};
    for (let [name, value] of formData.entries()) {
      data[name] = value;
    }
  };

  const handleAddSprint = () => {
    setState((prevState: any) => ({
      ...prevState,
      sprints: [
        ...prevState.sprints,
        {
          id: state.sprints.length + 1,
          name: '',
          sprintElements: [
            {
              id: 1,
              description: '',
              price_ht: '',
              price_ttc: '',
              quantity: '',
            },
          ],
        },
      ],
    }));
  };

  const handleAddSprintElement = (sprintIndex: any) => {
    setState((prevState: any) => {
      const sprints = [...prevState.sprints];
      const sprint = { ...sprints[sprintIndex] };
      sprint?.sprintElements.push({
        id: sprint?.sprintElements.length + 1,
        description: '',
        price_ht: '',
        price_ttc: '',
        quantity: '',
      });
      sprints[sprintIndex] = sprint;
      return { ...prevState, sprints };
    });
  };

  return (
    <DashboardLayout>
      <Link to={`/mission/${id}`}>
        <button className="flex flex-row p-3 bg-primary rounded-lg transition duration-150 ease-in hover:scale-105 text-white mt-5 ml-5">
          <FaChevronLeft className="mr-1" />
          Retour à la mission
        </button>
      </Link>

      <div className="m-5 w-fit flex">
        <form onSubmit={handleSubmit}>
          <div className="p-2">
            <div className="flex flex-col justify-center gap-2">
              <label className="text-sm text-mainBlack" htmlFor="missionName">
                Nom de la mission
              </label>
              <input
                type="text"
                defaultValue={state?.name}
                name="missionName"
              />
            </div>
            <div className="space-y-3 mt-5 rounded-xl p-3 bg-primary text-white flex flex-col">
              {state?.sprints?.map((sprint: any, sprintIndex: number) => (
                <div key={sprint?.id}>
                  <label>Nom du sprint</label>
                  <input
                    type="text"
                    defaultValue={sprint?.name}
                    name={`sprintName_${sprintIndex}`}
                  />
                  {sprint?.sprintElements.map(
                    (element: any, elementIndex: number) => (
                      <div
                        className="flex flex-row mt-3 space-y-5 space-x-5"
                        key={element?.id}
                      >
                        <div className="mt-5">Description</div>
                        <input
                          type="text"
                          defaultValue={element?.description}
                          name={`elementDescription_${sprintIndex}_${elementIndex}`}
                        />
                        <label>Prix HT</label>
                        <input
                          type="text"
                          defaultValue={element?.price_ht}
                          name={`elementPriceHt_${sprintIndex}_${elementIndex}`}
                        />
                        <label>Prix TTC</label>
                        <input
                          type="text"
                          defaultValue={element?.price_ttc}
                          name={`elementPriceTtc_${sprintIndex}_${elementIndex}`}
                        />
                        <label>Quantité</label>
                        <input
                          type="text"
                          defaultValue={element?.quantity}
                          name={`elementQuantity_${sprintIndex}_${elementIndex}`}
                        />
                      </div>
                    )
                  )}
                  <button
                    type="button"
                    className="w-full"
                    onClick={() => handleAddSprintElement(sprintIndex)}
                  >
                    Add a sprint element
                  </button>
                  <div className="h-0.5 rounded-md bg-black mx-3 mt-2"></div>
                </div>
              ))}
              <button type="button" onClick={handleAddSprint}>
                Add a sprint
              </button>
            </div>
          </div>

          <button
            type="submit"
            className="flex w-fit p-3 bg-primary rounded-lg transition duration-150 ease-in hover:scale-105 mt-5 text-white"
          >
            <FaSave className="mr-1" />
            Sauvegarder
          </button>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default UpdateMission;
