import React, { useState } from 'react';
import MissionBulle from '../MissionBulle';
import PopUp from '../PopUp';
import ProgressBar from '../ProgressBar';

function PaymentMethod({ data, setData, handleNext, handlePrevious }: any) {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      payment_method_ids: event.target.value,
    });
  };

  const handleNextStep = () => {
    if (data.payment_method_ids.length === 0) {
      setShowPopup(true);
    } else {
      handleNext();
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmPopup = () => {};
  const handleCancelPopup = () => {};

  return (
    <div className="flex flex-col items-center justify-center w-full h-full overflow-hidden">
      {showPopup && (
        <PopUp
          title="Choisissez une option"
          text="Veuillez choisir une option avant de continuer."
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleClosePopup}
          style={{ zIndex: 999 }}
        />
      )}
      <div
        className="p-2 w-full flex flex-row"
        style={{ height: '100%', padding: 30 }}
      >
        <div className="w-3/5 pr-4 relative flex flex-col">
          <ProgressBar percent="50" />
          <div className="flex flex-col gap-6 mt-4" style={{ height: '100%' }}>
            <h1 className="text-xl font-semibold">
              Sélectionnez la formule de paiement acceptée pour votre prestation
            </h1>
            <label
              className={`bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 checkbox-label ${
                data.payment_method_ids === 'progressive' ? 'selected' : ''
              }`}
            >
              <input
                type="checkbox"
                className="form-checkbox"
                value="progressive"
                onChange={handleCheckboxChange}
                checked={data.payment_method_ids === 'progressive'}
              />
              <span
                className={`text-lg font-medium pl-2 ${
                  data.payment_method_ids === 'progressive'
                    ? 'text-blue-500'
                    : ''
                }`}
              >
                Facturation progressive
              </span>
              {/* subtitle */}
              <p className="text-sm text-gray-600 pt-2">
                La facturation progressive permet de facturer le client au fur
                et à mesure de l'avancement de la mission. Cela permet de
                sécuriser le paiement de votre prestation.
              </p>
            </label>
            <label
              className={`bg-gray-50 text-gray-900 opacity-50 text-sm rounded-lg block w-full p-2.5 checkbox-label ${
                data.payment_method_ids === 'row' ? 'selected' : ''
              }`}
            >
              <input
                type="checkbox"
                className="form-checkbox"
                value="row"
                onChange={handleCheckboxChange}
                disabled
                checked={data.payment_method_ids === 'row'}
              />
              <span
                className={`text-lg font-medium pl-2 ${
                  data.payment_method_ids === 'row' ? 'text-blue-500' : ''
                }`}
              >
                Comptant
              </span>
              {/* subtitle */}
              <p className="text-sm text-gray-600 pt-2">
                La facturation comptant permet de facturer le client en une
                seule fois à la fin de la mission. Cela permet de sécuriser le
                paiement de votre prestation.
              </p>
            </label>
            <label
              className={`bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 checkbox-label ${
                data.payment_method_ids === 'esc' ? 'selected' : ''
              }`}
            >
              <input
                type="checkbox"
                className="form-checkbox"
                value="bank_print"
                onChange={handleCheckboxChange}
                checked={data.payment_method_ids === 'bank_print'}
              />
              <span
                className={`text-lg items-center font-medium pl-2 ${
                  data.payment_method_ids === 'bank_print'
                    ? 'text-blue-500'
                    : ''
                }`}
              >
                Empreinte bancaire
                {/* add recommanded tag */}
                <span className="text-[10px] bg-blue-800 text-white rounded-full p-1 ml-2">
                  Recommandé
                </span>
              </span>
              {/* subtitle */}
              <p className="text-sm text-gray-600 pt-2">
                L'empreinte bancaire vous permet de profité des services de
                Luter pour encaisser le paiement d'un sprint à l'avance et de
                l'encaisser dès lors que le sprint est validé par le client.
              </p>
            </label>
          </div>
          <div className="mt-20 text-right flex items-center justify-between">
            <button
              onClick={handlePrevious}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 transform hover:scale-110"
            >
              Retour
            </button>
            <button
              onClick={handleNextStep}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 transform hover:scale-110"
            >
              Suivant
            </button>
          </div>
        </div>
        <div className="w-2/5 pl-4" style={{ height: '100%' }}>
          <MissionBulle
            subtitle="Pourquoi ce choix est primordial ?"
            text="Le choix de la formule de paiement est primordial pour la réussite de votre mission. Il est important de choisir une formule qui convient au deux parties du contrat."
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentMethod;
