import React from 'react';
import { Link } from 'react-router-dom';
import { CiBellOn } from 'react-icons/ci';
import { useSelector } from 'react-redux';

type Props = {};

const NotificationBell = (props: Props) => {
  const notifications = useSelector(
    (state: any) => state.user.user.notifications
  );

  const unreadNotifications =
    notifications?.length > 0
      ? notifications.filter((notification: any) => !notification.isRead)
      : [];

  return (
    <Link
      to={'/notifications'}
      className="rounded-full bg-whiteGrey hover:bg-lightGrey p-2 cursor-pointer relative"
    >
      {unreadNotifications.length > 0 && (
        <div className="absolute -top-2 -right-2 rounded-full bg-red-500 text-white text-xs w-5 h-5 flex items-center justify-center">
          {unreadNotifications.length}
        </div>
      )}
      <CiBellOn className="text-mainBlack" size={24} />
    </Link>
  );
};

export default NotificationBell;
