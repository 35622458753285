import React from 'react';

function AngryIcon() {
  return (
    <svg
      width={30}
      height={31}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: '7px' }}
    >
      <path
        d="M12.976 25.73c6.885 0 12.467-5.582 12.467-12.467 0-6.885-5.582-12.467-12.467-12.467C6.091.796.51 6.378.51 13.263c0 6.885 5.582 12.467 12.467 12.467z"
        fill="url(#paint0_linear_14_1381)"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.809 15.58a5.886 5.886 0 018.326 0M7.112 8.798H18.84"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.646 10.383a.73.73 0 100-1.46.73.73 0 000 1.46zM15.543 10.362a.73.73 0 100-1.46.73.73 0 000 1.46z"
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14_1381"
          x1={12.9761}
          y1={0.795898}
          x2={12.9761}
          y2={25.7298}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.477265} stopColor="#FF7575" />
          <stop offset={1} stopColor="#FF9F00" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AngryIcon;
