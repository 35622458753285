import React, { useState } from 'react';
import MissionBulle from '../MissionBulle';
import PopUp from '../PopUp';
import ProgressBar from '../ProgressBar';
import { Link } from 'react-router-dom';
import { MissionInfo } from '../../globals';

function FormulaStep({ data, setData, handleNext, handlePrevious }: any) {
  const [subtitle, setSubtitle] = useState('');
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [luterPercentage, setLuterPercentage] = useState<number>(2);
  const [luterAccompagnement, setLuterAccompagnement] = useState<string>(
    'Cette formule porpose un accompagnement par un Chatbot qui saura vous aider en cas de problème.'
  );

  const handleRadioChange = (value: string) => {
    if (value === 'Green') {
      setSubtitle('Formule Green');
      setData((prevData: MissionInfo) => ({
        ...prevData,
        formula: 'Green',
      }));
      setLuterPercentage(2);
      setLuterAccompagnement(
        'Cette formule porpose un accompagnement par un Chatbot qui saura vous aider en cas de problème.'
      );
    } else if (value === 'Blue') {
      setSubtitle('Formule Blue');
      setData((prevData: MissionInfo) => ({
        ...prevData,
        formula: 'Blue',
      }));
      setLuterPercentage(7);
      setLuterAccompagnement(
        'Cette formule porpose un accompagnement par un Chatbot qui saura vous aider en cas de problème, ainsi que la création des documents relatifs à votre mission.'
      );
    } else if (value === 'Purple') {
      setSubtitle('Formule Purple');
      setData((prevData: MissionInfo) => ({
        ...prevData,
        formula: 'Purple',
      }));
      setLuterPercentage(11);
      setLuterAccompagnement(
        "Cette formule porpose un accompagnement par un Chatbot qui saura vous aider en cas de problème, la création des documents relatifs à votre mission, ainsi que l'aide d'un avocat en cas de litige."
      );
    }
  };

  const handleNextStep = () => {
    if (!data.formula) {
      setShowPopup(true);
    } else {
      handleNext();
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmPopup = () => {
    console.log('Popup confirmé!');
  };
  const handleCancelPopup = () => {
    console.log('Popup annulé!');
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full overflow-hidden">
      {showPopup && (
        <PopUp
          title="Choisissez une option"
          text="Veuillez choisir une option avant de continuer."
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleClosePopup}
          style={{ zIndex: 999 }}
        />
      )}
      <div className="h-full p-2 w-full flex" style={{ padding: 30 }}>
        <div className="w-3/5 pr-4 flex flex-col">
          <ProgressBar percent="25" />
          <h1 className="text-2xl font-bold my-16">
            Quelle formule vous conviendrait ?
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
            <label
              className={`cursor-pointer bg-white shadow-md rounded-lg p-6 flex flex-col items-center justify-between border-2 hover:scale-110 transform transition duration-300 ${
                data.formula === 'Green'
                  ? 'border-green-500'
                  : 'border-transparent'
              }`}
              onClick={() => handleRadioChange('Green')}
            >
              <input
                type="radio"
                className="hidden"
                value="Green"
                checked={data.formula === 'Green'}
                readOnly
              />
              <div className="text-lg font-medium text-green-500">Green</div>
              <div className="text-gray-700 mt-4 text-center">
                Pour les missions de courte durée avec un besoin
                d&apos;accompagnement minime.
              </div>
              <div className="text-gray-500 mt-2">2% de commission</div>
            </label>
            <label
              className={`cursor-pointer bg-white shadow-md rounded-lg p-6 flex flex-col items-center justify-between border-2 hover:scale-110 transform transition duration-300 ${
                data.formula === 'Blue'
                  ? 'border-blue-500'
                  : 'border-transparent'
              }`}
              onClick={() => handleRadioChange('Blue')}
            >
              <input
                type="radio"
                className="hidden"
                value="Blue"
                checked={data.formula === 'Blue'}
                readOnly
              />
              <div className="text-lg font-medium text-blue-500">Blue</div>
              <div className="text-gray-700 mt-4 text-center">
                Pour les missions de moyenne durée avec un besoin
                d&apos;accompagnement modéré.
              </div>
              <div className="text-gray-500 mt-2">7% de commission</div>
            </label>
            <label
              className={`cursor-pointer bg-white shadow-md rounded-lg p-6 flex flex-col items-center justify-between border-2 hover:scale-110 transform transition duration-300 ${
                data.formula === 'Purple'
                  ? 'border-purple-500'
                  : 'border-transparent'
              }`}
              onClick={() => handleRadioChange('Purple')}
            >
              <input
                type="radio"
                className="hidden"
                value="Purple"
                checked={data.formula === 'Purple'}
                readOnly
              />
              <div className="text-lg font-medium text-purple-500">Purple</div>
              <div className="text-gray-700 mt-4 text-center">
                Pour les missions de longue durée avec un besoin
                d&apos;accompagnement important.
              </div>
              <div className="text-gray-500 mt-2">11% de commission</div>
            </label>
          </div>
          <div className="mt-20 w-full flex justify-between">
            <Link to="/dashboard">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 transform hover:scale-110">
                Retour
              </button>
            </Link>
            <button
              onClick={handleNextStep}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 transform hover:scale-110"
            >
              Suivant
            </button>
          </div>
        </div>
        <div className="w-2/5 pl-4">
          <MissionBulle subtitle={subtitle} text={luterAccompagnement} />
        </div>
      </div>
    </div>
  );
}

export default FormulaStep;
