import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';
import Table from '../../Components/molecules/Table';
import { AiFillEye, AiOutlineDownload } from 'react-icons/ai';
import { useMemo } from 'react';
import { useGetDocumentsQuery } from '../../redux/services/missions';
import moment from 'moment';

const MissionDocuments = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: documents } = useGetDocumentsQuery(parseInt(id as string), {
    refetchOnMountOrArgChange: true,
  });

  const files = useMemo(() => {
    if (!documents || !Array.isArray(documents)) {
      return [];
    }

    return documents.map((document) => {
      const row = [
        document.name,
        document.requiredSignatures ? (
          <div key="2" className="flex gap-2 items-center px-2">
            {document.signatures.length}
          </div>
        ) : (
          <div key="2" className="flex gap-2 items-center">
            Aucune signature requise
          </div>
        ),
        moment(document.createdAt).format('DD/MM/YYYY'),
      ];

      const actions = [
        {
          label: <AiFillEye size={24} />,
          onClick: () => navigate(`/mission/${id}/document/${document.id}`),
        },
        {
          label: <AiOutlineDownload size={24} />,
          onClick: () => (window.location.href = document.fileUrl),
        },
      ];

      return {
        row,
        actions,
      };
    });
  }, [documents, id]);

  return (
    <DashboardLayout>
      <div className="flex justify-between items-center">
        <h2 className="text-mainBlack text-xl font-semibold">
          Documents de la mission
        </h2>
        <button
          onClick={() => navigate(`/mission/${id}/document/new`)}
          className="flex items-center gap-2 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
        >
          <span>Ajouter un document</span>
        </button>
      </div>
      <div className="mt-6">
        <Table
          columns={['Nom', 'Signatures', 'Date', 'Actions']}
          data={files}
        />
      </div>
    </DashboardLayout>
  );
};

export default MissionDocuments;
