import React from 'react';
import { CiSettings } from 'react-icons/ci';
import DarkModeSwitcher from './molecules/Dark';

const ClientNavbar = () => {
  return (
    <nav className="flex gap-6 xl:gap-0 justify-end items-center px-4 py-2 z-50 absolute top-0 w-full h-24 bg-gradient">
      <div className="flex justify-end gap-4">
        <button className="rounded-full bg-whiteGrey hover:bg-lightGrey p-2">
          <CiSettings className="text-mainBlack" size={24} />
        </button>
        <DarkModeSwitcher />
      </div>
    </nav>
  );
};

export default ClientNavbar;
