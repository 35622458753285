import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import IncomeChart from '../Components/IncomeChart';
import DashboardLayout from '../Layout/DashboardLayout';
import { BsBriefcaseFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ProductFruits } from 'react-product-fruits';

const Dashboard = () => {
  const user = useSelector((state: any) => state.user.user);
  const navigate = useNavigate();

  // Get current month and year
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const monthNames = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  // Get current month name
  const currentMonth = monthNames[currentMonthIndex];

  // Filter projectUsers to include only projects that finish in the current month
  const currentMonthFinishedProjects = user.projectUsers.filter(
    (projectUser: any) => {
      const projectEndDate = new Date(projectUser.project.end_date);
      return (
        projectEndDate.getMonth() === currentMonthIndex &&
        projectEndDate.getFullYear() === currentYear
      );
    }
  );

  // Calculate income for each project that finishes in the current month
  const currentMonthIncomeData = currentMonthFinishedProjects.map(
    (projectUser: any) => {
      const project = projectUser.project;
      const income = project.sprints.reduce(
        (acc: any, sprint: any) => acc + sprint.price_ttc,
        0
      );
      return {
        projectName: project.name,
        income,
      };
    }
  );

  const inProgressProjects = user.projectUsers.filter((projectUser: any) => {
    return projectUser.project.status === 'in_progress';
  });

  const numberOfMissionsInProgress = inProgressProjects.length;
  const userInfo = {
    username: user.first_name,
  };

  const handleClick = () => {
    if (user.siret === '12345678901234' || user.tva === 'FRXX123456789') {
      toast.warning(
        "Veuillez rentrer SIRET et numéro de TVA dans l'onglet facturation des réglages"
      );
    } else {
      navigate('/mission/new');
    }
  };

  // Transform currentMonthIncomeData to match IncomeChart's expected format
  const chartData = currentMonthIncomeData.map((item: any) => ({
    month: item.projectName,
    year: currentMonth,
    income: item.income,
  }));

  return (
    <div>
      <DashboardLayout>
        <ProductFruits
          workspaceCode="heGXd2liLYWR5zyB"
          language="en"
          user={userInfo}
        />
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="text-4xl md:ml-8 text-mainBlack sm:ml-8 max-[640px]:text-center max-[640px]:my-4 max-[640px]:ml-8">
            Dashboard
          </div>
          <button
            className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded"
            onClick={handleClick}
          >
            <BsBriefcaseFill size={20} className="mr-2 text-white" />
            Créer une mission
          </button>
        </div>

        <div className="flex m-8 flex-col sm:flex-row">
          <div className="bg-fullWhite rounded-[20px] p-4 mr-4 flex-1 relative max-[640px]:m-4">
            <div className="text-primary mb-2">Missions en cours</div>
            <div className="text-primary text-[40px] font-bold text-center">
              {numberOfMissionsInProgress}
            </div>
          </div>
          <div className="bg-fullWhite rounded-[20px] p-4 ml-4 flex-1 relative max-[640px]:m-4">
            <div className="text-primary mb-2">
              Chiffre d&apos;affaires du mois actuel
            </div>
            <div className="text-primary text-[40px] font-bold text-center">
              {currentMonthIncomeData.reduce(
                (acc: any, data: any) => acc + data.income,
                0
              )}
              {'€'}
            </div>
          </div>
        </div>
        <div className="bg-fullWhite rounded-[20px] p-4 m-8 flex-1 relative">
          <IncomeChart data={chartData} />
        </div>
      </DashboardLayout>
    </div>
  );
};

export default Dashboard;
