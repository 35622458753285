import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Blocks } from 'react-loader-spinner';

const ClientCheckoutSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.opener = null;
      window.open('', '_self');
      window.close();
    }, 5000);
  }, [navigate]);

  return (
    <div className="flex justify-center items-center min-h-screen flex-col bg-gray-50">
      <div className="mb-4">
        <Blocks
          height="80"
          width="80"
          color="#2563EB"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
      <h1 className="text-3xl font-bold text-[#2563EB]-600 animate-fade-in">
        Paiement réussi !
      </h1>
      <p className="mt-4 text-lg animate-fade-in delay-1">
        Merci pour votre paiement. Cette fenêtre va se fermer automatiquement.
      </p>
      <p className="mt-2 text-sm text-gray-500 animate-fade-in delay-2">
        Si elle ne se ferme pas, vous pouvez la fermer manuellement.
      </p>
    </div>
  );
};

export default ClientCheckoutSuccess;
