import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateConfidence } from '../../redux/feature/formSlice';

// Icons
import AngryIcon from '../../assets/FeelEmotes/AngryIcon';
import HappyIcon from '../../assets/FeelEmotes/HappyIcon';
import SatisfiedIcon from '../../assets/FeelEmotes/SatisfiedIcon';
import NeutralIcon from '../../assets/FeelEmotes/NeutralIcon';

type BorderedIconProps = {
  id: string;
  icon: React.ReactNode;
  feeling: string;
  mainColor: string;
  setFeeling: (feeling: string) => void;
};

const BorderedIcon = ({
  mainColor,
  id,
  icon,
  feeling,
  setFeeling,
}: BorderedIconProps) => {
  return (
    <button
      className={`z-10 border-2 border-black w-12 h-12 rounded-full -ml-5 justify-center items-center bg-white ${
        feeling === id ? `border-${mainColor}` : 'border-black'
      } ${feeling === id ? 'scale-125' : ''}`}
      onClick={() => setFeeling(id)}
    >
      {icon}
    </button>
  );
};

const Separator = () => {
  return <div className="border-2 border-black w-20 h-2 -ml-5 z-0" />;
};

const FeelingInput = () => {
  const [feeling, setFeelingState] = useState<string>('');
  const dispatch = useDispatch();

  const setFeeling = (newFeeling: string) => {
    setFeelingState(newFeeling);
    dispatch(updateConfidence(newFeeling));
  };

  return (
    <div className="flex justify-center items-center w-full">
      <BorderedIcon
        mainColor="#FF7575"
        id="Pas confiant"
        setFeeling={setFeeling}
        feeling={feeling}
        icon={<AngryIcon />}
      />
      <Separator />
      <BorderedIcon
        mainColor="#FFE500"
        id="Peu confiant"
        setFeeling={setFeeling}
        feeling={feeling}
        icon={<NeutralIcon />}
      />
      <Separator />
      <BorderedIcon
        mainColor="#2AA0FF"
        id="Assez confiant"
        setFeeling={setFeeling}
        feeling={feeling}
        icon={<SatisfiedIcon />}
      />
      <Separator />
      <BorderedIcon
        mainColor="#26D998"
        id="Très confiant"
        setFeeling={setFeeling}
        feeling={feeling}
        icon={<HappyIcon />}
      />
    </div>
  );
};

export default FeelingInput;
