import React from 'react';
import { motion } from 'framer-motion';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer';

const ProjectPresentation: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className="font-Apfel min-h-screen">
        <div className="container mx-auto px-4 py-8">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-4xl mx-auto"
          >
            <h1 className="text-3xl font-bold mb-4 mt-20">
              Bienvenue sur{' '}
              <span className="text-primary font-bold">Luter</span>
            </h1>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              className="text-gray-700 text-lg mb-8 leading-relaxed"
            >
              <span className="text-primary font-bold">180 milliards</span>{' '}
              d&apos;euro, c&apos;est la faramineuse somme des factures impayées
              en France en 2021, un chiffre en expansion de
              <span className="text-primary font-bold"> 28%</span> comparé à
              l&apos;année précédente. Certes, cette statistique concerne toutes
              les classes d&apos;entreprise, les petites, les moyennes et les
              grandes notamment. Mais parallèlement,{' '}
              <span className="text-primary font-bold">1 million</span> de
              micro-entreprises ont été créées en 2O21, chiffre en évolution de
              <span className="text-primary font-bold"> 17%</span> de 2020 à
              2021.
              <br />
              <br />
              Alors peut-on y voir une corrélation ? On le pense, pire, on croit
              que les freelances sont ceux qui souffrent le plus de la
              malhonnêteté des clients, sachant que ces derniers sont souvent en
              proie à l&apos;isolement social. Ce problème peut en engendrer
              d&apos;autres, tel qu&apos;un dégoût de l&apos;activité, un stress
              lié à la survie de l&apos;entreprise, voire une dépression. Dès
              lors, qui va nous couvrir d&apos;une facture impayée lorsque
              celle-ci représente notre seul revenu du mois ?
              <br />
              <br />
              Nous avons alors mené notre enquête pour savoir si des solutions
              existaient, nous en avons trouvé, mais elles présentaient à chaque
              fois des défauts soit dans la fiabilité, soit dans le tarif, soit
              dans le temps de démarche. Nous sommes devenus auto-entrepreneurs
              pour tous les avantages que ce statut offre, sans
              s&apos;embarrasser dans des démarches d&apos;actions juridiques ou
              de prévention coûteuses et chronophages. Tout comme un salarié,
              nous souhaitons simplement rémunération lorsque nous travaillons
              et être sereins quant à notre situation financière.
              <br />
              <br />
              C&apos;est pour cela que nous avons créé{' '}
              <span className="text-primary font-bold">Luter</span>, une
              plateforme qui sécurise vos missions sur un commun d&apos;accord.
              L&apos;essence de cette dernière se trouve dans le système de
              tiers-confiance lors de l&apos;accord d&apos;un contrat, ce qui ne
              laisse aucune place à la tentative de fraude.
              <br />
              <br />
              Le principe de{' '}
              <span className="text-primary font-bold">Luter</span>,
              intelligible, se découpe en{' '}
              <span className="text-primary font-bold">3</span> parties :
              <br />
              <br />
              En premier, votre client vous propose une mission, vous
              l&apos;acceptez, puis vous proposez sous forme de vote vos
              modifications si nécessaire. Puis une fois le terrain
              d&apos;entente trouvé, le client doit directement procéder au
              paiement du service, et notre plateforme va bloquer de façon
              sécurisée les fonds. L&apos;argent est bien là ! Motivant pour
              démarrer une mission, n&apos;est-ce pas ? Enfin, une fois la
              mission délivrée et votre client satisfait, vous pouvez débloquer
              votre argent.
              <br />
              <br />
              Alors, êtes-vous prêt à faciliter votre vie ? Laissez-nous vous
              permettre de souffler un bon coup ! Ayant personnellement souffert
              des mauvais payeurs, notre équipe souhaite avant tout vous
              accompagner pour éviter que vous ne subissiez pas le même sort.
              Pour nous soutenir, vous pouvez nous suivre sur LinkedIn ou
              partager cet article à votre entourage !
            </motion.p>
          </motion.div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProjectPresentation;
